@charset 'UTF-8';

// ========================================
// サイトの基本設定
// ========================================

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900|Noto+Serif+JP:300,400,500,700,900&subset=japanese');

// 変数設定
// ----------------------------------------

// カラー
/* 標準色 */
$black:      #1f1f1f;
/* ベースカラー */
$main:      #000409;
$sub:       #00ace8;
$key:       #e3aa03;
$em:        #c00;
$link:      #005b99;
/* 補助色 */
$aux:       #707070;
/* 線など */
$pale:      #dedede;
/* 薄い背景 */
$thin:      #f2f3f6;



// フォント
$font: 'Noto Sans JP', sans-serif;
$font-min: 'Noto Serif JP', serif;
// $font: "MS P Gothic",HiraKakuProN,"HiraKakuProN-W3","Hiragino Kaku Gothic ProN W3","Helvetica",sans-serif;
// $font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, sans-serif;
// $font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
// $font-min: 'Sawarabi Mincho',Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
// $font-en: "Roboto Mono", serif;

// メディアクエリ
$breakpoints: (
    xl: 'only screen and (min-width: 1281px)',
    lg: 'only screen and (max-width: 1280px)',
    md: 'only screen and (max-width: 1000px)',
    sm: 'only screen and (max-width: 768px)',
    xs: 'only screen and (max-width: 480px)',
    etc: 'only screen and (min-width: 769px)',
);

$easeOutCubic: 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
$easeInOutCubic: 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);



// 基本設定
// ----------------------------------------
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    width: 100%;
    height: 100%;
}
body {
    font-size: calc(16px - 2*(100vw - 320px)/1280);
    font-family: $font;
    line-height: 1.8;
    font-weight: 300;
    width: 100%;
    height: 100%;
}
/*ie7*/
*:first-child+html body { font-size:75%; }
/*ie5-6*/
* html body { font-size:75%; }

// タッチデバイスの反応向上 reboot.cssより
// ----------------------------------------
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
-ms-touch-action: manipulation;
touch-action: manipulation; }

// 画像設定
// ----------------------------------------
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}



// カラー設定
// ----------------------------------------

a {
    color: $link;
    text-decoration: none;
}
a.line {
    text-decoration: underline;
    a:hover {
        text-decoration: none;
    }
}

// input
// ----------------------------------------
input[type="submit"] {
    background: none;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    font-size: 100%;
    cursor: pointer;
    &::-webkit-search-decoration {
        display: none;
    }
    &::focus {
        outline-offset: -2px;
    }
}


/* WordPress Native Styles
--------------------------------------------- */
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }

img[class*='wp-image-'],
img[class*='attachment-'] {
    max-width: 100%;
    height: auto;
}

.wp-caption {
    max-width: 96%;
    padding: 8px;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
    max-width: 100%;
}

.wp-caption p.wp-caption-text {
    margin: 0;
    padding: 5px 0;
    font-family: $font;
    font-size: 1.4rem;
}

.postContent h2 {
    font-size: 2.2rem;
}
.postContent h3 {
    font-size: 2.0rem;
}
.postContent h4 {
    font-size: 1.8rem;
}
.postContent h5 {
    font-size: 1.6rem;
}
.postContent h6 {
    font-size: 1.4rem;
}

.postContent p {
    margin-bottom: 10px;
}
.postContent pre {
    background-color: $thin;
    padding: 8px;
    margin: 16px 0;
    font-family: $font;
    font-size: 1.4rem;
}
.postContent strong {
    font-weight: bold;
}
.postContent em {
    font-style: italic;
}
.postContent ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
}
.postContent blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 7px solid #ccc;
}
.postContent hr {
    border-top: 1px solid #ccc;
    margin: 20px 5px;
}
.postContent .aligncenter,
.postContent div.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.postContent .alignleft {
    float: left;
    margin-right:10px;
}
.postContent .alignright {
    float: right;
}
.postContent .wp-caption-text {
    font-size: 1.2rem;
}

