@charset "UTF-8";
/*
Theme Name: dental
Theme URI:
Description: dental
Version: 1.0
Author:コウベビューティー株式会社
Author URI: https://kobe-beauty.co.jp/
Tags: one-column
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900|Noto+Serif+JP:300,400,500,700,900&subset=japanese");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms;
}

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0;
}

body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms;
}

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0;
}

body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
  transition-delay: 100ms;
}

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms;
}

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0;
}

body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
  transition-delay: 150ms;
}

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms;
}

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0;
}

body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
  transition-delay: 200ms;
}

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms;
}

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0;
}

body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
  transition-delay: 250ms;
}

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms;
}

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0;
}

body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
  transition-delay: 300ms;
}

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms;
}

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0;
}

body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
  transition-delay: 350ms;
}

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms;
}

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0;
}

body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
  transition-delay: 400ms;
}

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms;
}

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0;
}

body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
  transition-delay: 450ms;
}

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms;
}

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0;
}

body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
  transition-delay: 500ms;
}

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms;
}

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0;
}

body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
  transition-delay: 550ms;
}

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms;
}

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0;
}

body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
  transition-delay: 600ms;
}

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms;
}

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0;
}

body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
  transition-delay: 650ms;
}

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms;
}

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0;
}

body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
  transition-delay: 700ms;
}

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms;
}

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0;
}

body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
  transition-delay: 750ms;
}

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms;
}

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0;
}

body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
  transition-delay: 800ms;
}

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms;
}

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0;
}

body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
  transition-delay: 850ms;
}

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms;
}

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0;
}

body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
  transition-delay: 900ms;
}

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms;
}

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0;
}

body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
  transition-delay: 950ms;
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms;
}

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0;
}

body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
  transition-delay: 1000ms;
}

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms;
}

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0;
}

body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
  transition-delay: 1050ms;
}

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms;
}

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0;
}

body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
  transition-delay: 1100ms;
}

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms;
}

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0;
}

body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
  transition-delay: 1150ms;
}

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms;
}

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0;
}

body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
  transition-delay: 1200ms;
}

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms;
}

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0;
}

body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
  transition-delay: 1250ms;
}

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms;
}

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0;
}

body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
  transition-delay: 1300ms;
}

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms;
}

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0;
}

body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
  transition-delay: 1350ms;
}

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms;
}

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0;
}

body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
  transition-delay: 1400ms;
}

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms;
}

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0;
}

body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
  transition-delay: 1450ms;
}

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms;
}

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0;
}

body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
  transition-delay: 1500ms;
}

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms;
}

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0;
}

body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
  transition-delay: 1550ms;
}

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms;
}

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0;
}

body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
  transition-delay: 1600ms;
}

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms;
}

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0;
}

body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
  transition-delay: 1650ms;
}

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms;
}

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0;
}

body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
  transition-delay: 1700ms;
}

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms;
}

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0;
}

body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
  transition-delay: 1750ms;
}

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms;
}

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0;
}

body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
  transition-delay: 1800ms;
}

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms;
}

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0;
}

body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
  transition-delay: 1850ms;
}

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms;
}

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0;
}

body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
  transition-delay: 1900ms;
}

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms;
}

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0;
}

body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
  transition-delay: 1950ms;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms;
}

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0;
}

body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
  transition-delay: 2000ms;
}

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms;
}

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0;
}

body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
  transition-delay: 2050ms;
}

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms;
}

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0;
}

body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
  transition-delay: 2100ms;
}

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms;
}

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0;
}

body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
  transition-delay: 2150ms;
}

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms;
}

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0;
}

body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
  transition-delay: 2200ms;
}

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms;
}

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0;
}

body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
  transition-delay: 2250ms;
}

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms;
}

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0;
}

body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
  transition-delay: 2300ms;
}

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms;
}

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0;
}

body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
  transition-delay: 2350ms;
}

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms;
}

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0;
}

body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
  transition-delay: 2400ms;
}

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms;
}

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0;
}

body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
  transition-delay: 2450ms;
}

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms;
}

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0;
}

body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
  transition-delay: 2500ms;
}

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms;
}

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0;
}

body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
  transition-delay: 2550ms;
}

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms;
}

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0;
}

body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
  transition-delay: 2600ms;
}

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms;
}

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0;
}

body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
  transition-delay: 2650ms;
}

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms;
}

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0;
}

body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
  transition-delay: 2700ms;
}

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms;
}

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0;
}

body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
  transition-delay: 2750ms;
}

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms;
}

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0;
}

body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
  transition-delay: 2800ms;
}

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms;
}

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0;
}

body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
  transition-delay: 2850ms;
}

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms;
}

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0;
}

body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
  transition-delay: 2900ms;
}

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms;
}

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0;
}

body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
  transition-delay: 2950ms;
}

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms;
}

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0;
}

body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translate(0, 0);
}

[data-aos='fade-up'] {
  transform: translate(0, 100px);
}

[data-aos='fade-down'] {
  transform: translate(0, -100px);
}

[data-aos='fade-right'] {
  transform: translate(-100px, 0);
}

[data-aos='fade-left'] {
  transform: translate(100px, 0);
}

[data-aos='fade-up-right'] {
  transform: translate(-100px, 100px);
}

[data-aos='fade-up-left'] {
  transform: translate(100px, 100px);
}

[data-aos='fade-down-right'] {
  transform: translate(-100px, -100px);
}

[data-aos='fade-down-left'] {
  transform: translate(100px, -100px);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

[data-aos='zoom-in'] {
  transform: scale(0.6);
}

[data-aos='zoom-in-up'] {
  transform: translate(0, 100px) scale(0.6);
}

[data-aos='zoom-in-down'] {
  transform: translate(0, -100px) scale(0.6);
}

[data-aos='zoom-in-right'] {
  transform: translate(-100px, 0) scale(0.6);
}

[data-aos='zoom-in-left'] {
  transform: translate(100px, 0) scale(0.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate(0, 100px) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate(0, -100px) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate(-100px, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate(100px, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;
}

[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate(0, 0);
}

[data-aos='slide-up'] {
  transform: translateY(100%);
}

[data-aos='slide-down'] {
  transform: translateY(-100%);
}

[data-aos='slide-right'] {
  transform: translateX(-100%);
}

[data-aos='slide-left'] {
  transform: translateX(100%);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos='flip-left'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos='flip-right'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos='flip-up'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos='flip-down'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* 標準色 */
/* ベースカラー */
/* 補助色 */
/* 線など */
/* 薄い背景 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
  font-size: calc(16px - 2*(100vw - 320px)/1280);
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.8;
  font-weight: 300;
  width: 100%;
  height: 100%;
}

/*ie7*/
*:first-child + html body {
  font-size: 75%;
}

/*ie5-6*/
* html body {
  font-size: 75%;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #005b99;
  text-decoration: none;
}

a.line {
  text-decoration: underline;
}

a.line a:hover {
  text-decoration: none;
}

input[type="submit"] {
  background: none;
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  font-size: 100%;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus {
  outline-offset: -2px;
}

/* WordPress Native Styles
--------------------------------------------- */
.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

img[class*='wp-image-'],
img[class*='attachment-'] {
  max-width: 100%;
  height: auto;
}

.wp-caption {
  max-width: 96%;
  padding: 8px;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
  max-width: 100%;
}

.wp-caption p.wp-caption-text {
  margin: 0;
  padding: 5px 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.4rem;
}

.postContent h2 {
  font-size: 2.2rem;
}

.postContent h3 {
  font-size: 2.0rem;
}

.postContent h4 {
  font-size: 1.8rem;
}

.postContent h5 {
  font-size: 1.6rem;
}

.postContent h6 {
  font-size: 1.4rem;
}

.postContent p {
  margin-bottom: 10px;
}

.postContent pre {
  background-color: #f2f3f6;
  padding: 8px;
  margin: 16px 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.4rem;
}

.postContent strong {
  font-weight: bold;
}

.postContent em {
  font-style: italic;
}

.postContent ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.postContent ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 20px;
}

.postContent blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 7px solid #ccc;
}

.postContent hr {
  border-top: 1px solid #ccc;
  margin: 20px 5px;
}

.postContent .aligncenter,
.postContent div.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.postContent .alignleft {
  float: left;
  margin-right: 10px;
}

.postContent .alignright {
  float: right;
}

.postContent .wp-caption-text {
  font-size: 1.2rem;
}

.sec-clip {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  padding: 120px 10%;
}

.sec-clip-inner {
  background-color: white;
  padding: 80px 5%;
  border: 10px solid rgba(255, 255, 255, 0.3);
  background-clip: padding-box;
}

.sec-clip hgroup {
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.sec-clip .number {
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  font-weight: black;
  line-height: 1.3;
}

.sec-clip .title span {
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  font-weight: black;
  line-height: 1.3;
}

.sec-main {
  max-width: 960px;
  padding: 0 5%;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  display: flex;
  flex-flow: row wrap;
  margin: -20px 0 0 -20px;
}

.grid.xl-4 .col {
  flex: 0 0 25%;
  max-width: 25%;
}

.grid.xl-3 .col {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.grid.xl-2 .col {
  flex: 0 0 50%;
  max-width: 50%;
}

.grid .col {
  display: block;
  align-self: center;
  text-align: center;
  padding: 20px 0 0 20px;
  min-width: 0;
}

a:hover .button {
  border: 1px solid #000409;
  background-color: #000409;
  color: white;
}

.button {
  display: inline-block;
  padding: 16px 40px;
  min-width: 240px;
  position: relative;
  border-radius: 100px;
  border: 1px solid white;
  background-color: white;
  color: #000409;
  transition: color .3s, background .3s, border .3s;
  text-align: center;
  line-height: 1.3;
}

@media only screen and (max-width: 480px) {
  .button {
    min-width: 200px;
  }
}

.btn a {
  display: inline-block;
  padding: 16px 40px;
  min-width: 240px;
  position: relative;
  border-radius: 100px;
  border: 1px solid #000409;
  background-color: #000409;
  color: white;
  transition: color .3s, background .3s;
  text-align: center;
  line-height: 1.3;
}

.btn a:hover {
  border: 1px solid #000409;
  background-color: white;
  color: #000409;
}

.title-lg {
  font-size: calc(24px + 12*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .title-lg {
    font-weight: 500;
  }
}

.title-md {
  font-size: calc(20px + 10*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .title-md {
    font-weight: 500;
  }
}

.title-sm {
  font-size: calc(16px + 2*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .title-sm {
    font-weight: 500;
  }
}

.title-main {
  font-size: calc(24px + 16*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .title-main {
    font-weight: 500;
  }
}

.title-main-b {
  font-size: calc(24px + 16*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 700;
}

.title-sub {
  font-size: calc(18px + 12*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .title-sub {
    font-weight: 500;
  }
}

.title-sub-b {
  font-size: calc(18px + 12*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 700;
}

.title-blur span {
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(images/mv-concept.jpg);
  color: rgba(0, 0, 0, 0.4);
  font-size: calc(18px + 20*(100vw - 320px)/1280);
  font-weight: 700;
  line-height: 1.3;
}

@media all and (-ms-high-contrast: none) {
  .title-blur span {
    background-image: none;
    color: #1f1f1f;
  }
}

.title-bg {
  position: relative;
  display: table;
  width: 100%;
  min-height: 400px;
}

@media only screen and (max-width: 768px) {
  .title-bg {
    min-height: 300px;
  }
}

.title-bg .title {
  display: table-cell;
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  text-align: center;
  color: white;
  font-weight: 400;
  letter-spacing: 10px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .title-bg .title {
    letter-spacing: 5px;
  }
}

.title-bg .pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  z-index: -1;
}

.block-memo {
  padding: 0 5%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  color: #707070;
}

.block-article {
  max-width: 960px;
  padding: 0 5%;
  margin-right: auto;
  margin-left: auto;
}

.block-article--wrap {
  overflow-x: hidden;
}

.block-article p {
  text-align: justify;
}

.block-article h1 + p, .block-article h2 + p {
  margin-top: 30px;
}

.block-article h3 + p, .block-article h4 + p {
  margin-top: 20px;
}

.block-article figure + p, .block-article p + figure {
  margin-top: 80px;
}

@media only screen and (max-width: 768px) {
  .block-article figure + p, .block-article p + figure {
    margin-top: 40px;
  }
}

.block-article p + p {
  margin-top: 30px;
}

.block-lead {
  text-align: center;
  padding: 0 5%;
  margin-right: auto;
  margin-left: auto;
}

.block-lead p + p {
  margin-top: 20px;
}

.block-map {
  width: 100%;
  height: 600px;
}

@media only screen and (max-width: 768px) {
  .block-map {
    height: 50vh;
  }
}

.block-3column {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 960px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.block-3column--item {
  max-width: 32%;
  margin-bottom: 40px;
}

.block-3column .caption {
  margin-top: 20px;
}

.block-3column .caption .title {
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: normal;
  color: #1f1f1f;
}

.block-3column .caption .title span {
  font-weight: bold;
  display: block;
}

.block-4column {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .block-4column {
    width: 90%;
  }
}

.block-4column--list {
  display: flex;
  flex-flow: row wrap;
  margin: -20px 0 0 -10px;
}

@media only screen and (max-width: 768px) {
  .block-4column--list {
    margin: -40px 0 0 -10px;
  }
}

.block-4column--item {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 20px 0 0 10px;
}

@media only screen and (max-width: 768px) {
  .block-4column--item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 40px 0 0 10px;
  }
}

.block-4column .caption {
  margin-top: 20px;
}

.block-4column .caption .title {
  font-size: calc(14px + 4*(100vw - 320px)/1280);
  line-height: 1.3;
  color: #1f1f1f;
  font-weight: normal;
}

.block-bnr-em--anchor {
  position: relative;
  width: 100%;
  background-size: cover;
  display: block;
}

.block-bnr-em--anchor .caption {
  padding-left: 10%;
  padding-right: 10%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.block-bnr-em--anchor .caption .title {
  font-size: 1.4rem;
  color: white;
  padding-bottom: 40px;
}

.block-bnr-em--anchor .caption .copy {
  font-size: 4.0rem;
  font-weight: normal;
  color: white;
  line-height: 1.3;
}

.grid-link {
  width: 80%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .grid-link {
    width: 90%;
  }
}

.grid-link--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.grid-link--item {
  width: calc(50% - 5px);
  position: relative;
  margin-bottom: 10px;
}

.grid-link--item-full {
  width: 100%;
}

.grid-link .main-title {
  font-size: 1.6rem;
  line-height: 1.3;
  margin-bottom: 10px;
}

.grid-link .caption {
  position: absolute;
  top: 5%;
  width: 100%;
}

.grid-link .caption .title {
  font-size: calc(16px + 8*(100vw - 320px)/1280);
  line-height: 1.3;
  text-align: center;
  color: white;
  text-shadow: #707070 0 0 10px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .table-wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.table-wrap th, .table-wrap td {
  white-space: nowrap;
}

.table-simple {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  line-height: 1.3;
}

.table-simple thead {
  background-color: #f2f3f6;
}

.table-simple th, .table-simple td {
  border: 1px solid #dedede;
  padding: 20px 10px;
  font-weight: 400;
}

.table-simple.t-center {
  text-align: center;
}

.table-simple.t-noborder {
  border: none;
}

.table-simple.t-noborder th, .table-simple.t-noborder td {
  border: none;
  padding: 10px;
}

.table-simple.th-left th {
  text-align: left;
}

.table-simple.td-right td {
  text-align: right;
}

.table-simple.td-center td {
  text-align: center;
}

.table-row {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .table-row {
    border-collapse: separate;
    border-spacing: 0;
  }
}

.table-row thead {
  background-color: #f2f3f6;
}

.table-row th, .table-row td {
  border: 1px solid #dedede;
  padding: 20px 10px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .table-row th, .table-row td {
    display: block;
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .table-row th {
    padding: 15px 10px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .table-row td {
    padding: 0 10px 15px;
  }
}

.table-row tr:not(:first-child) th {
  border-top: 1px solid #dedede;
}

.list-circle > li {
  position: relative;
  padding-left: 18px;
}

.list-circle > li:before {
  position: absolute;
  content: "●";
  top: 0;
  left: 0;
}

.list-asta > li {
  position: relative;
  padding-left: 18px;
}

.list-asta > li:before {
  position: absolute;
  content: "＊";
  top: 0;
  left: 0;
}

.list-note > li {
  position: relative;
  padding-left: 18px;
}

.list-note > li:before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}

.list-disc > li {
  position: relative;
  padding-left: 14px;
}

.list-disc > li:before {
  position: absolute;
  content: "・";
  top: 0;
  left: 0;
}

.list-number {
  list-style-type: none;
  list-style-position: inside;
}

.list-number > li {
  padding-left: 1.5em;
  text-indent: -1.5em;
  counter-increment: cnt;
  text-align: justify;
}

.list-number > li:before {
  display: marker;
  content: "0" counter(cnt) " ";
  margin-right: 3px;
  color: #707070;
}

.list-number > li:nth-child(n+10):before {
  content: "" counter(cnt) " ";
}

.list-flow-down .flow {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px 10px;
  min-width: 120px;
  max-width: 100%;
  color: white;
  background: #000409;
  text-align: center;
  line-height: 1.3;
}

.list-flow-down .flow:not(:last-child):before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #000409;
}

.list-flow-down .flow:not(:first-child) {
  margin-top: 30px;
}

.zoom {
  overflow: hidden;
}

a:hover .zoom img {
  transform: scale(1.05);
}

.zoom img {
  transition: transform .3s;
}

.fig-caption {
  margin: -10px 0;
}

.fig-caption img {
  padding: 10px 0;
}

.fig-caption figcaption {
  margin: 10px 0;
  font-size: 86%;
  line-height: 1.4;
  color: #707070;
}

.fig-caption-lg {
  margin: 1em calc(50% - 50vw);
}

.fig-caption-lg img {
  width: 100%;
  height: auto;
}

.fig-caption-lg figcaption {
  margin: 10px auto;
  max-width: 960px;
  padding: 0 5%;
  font-size: 86%;
  line-height: 1.4;
  color: #707070;
}

.release-pic {
  margin: 1em calc(50% - 50vw);
}

.release-pic .pic {
  width: 100%;
  height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.release-pic .notes {
  margin: 10px auto;
  max-width: 960px;
  padding: 0 5%;
  font-size: 86%;
  line-height: 1.4;
  color: #707070;
}

/* sef
--------------------------------------------- */
.sef {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.sef.sef-ori {
  opacity: 1;
}

.sef.show {
  opacity: 1;
  transform: none;
}

.sef-left {
  transform: translate(-100px, 0);
}

.sef-right {
  transform: translate(100px, 0);
}

.sef-up {
  transform: translate(0, 100px);
}

.sef-down {
  transform: translate(0, -100px);
}

.sef-scaleUp {
  transform: scale(0.5);
}

.sef-scaleDown {
  transform: scale(1.5);
}

/* カラー
--------------------------------------------- */
.c-white {
  color: white;
}

.c-black {
  color: #1f1f1f;
}

.c-aux {
  color: #707070;
}

.c-pale {
  color: #dedede;
}

.c-thin {
  color: #f2f3f6;
}

.c-main {
  color: #000409;
}

.c-sub {
  color: #00ace8;
}

.c-key {
  color: #e3aa03;
}

.c-em {
  color: #c00;
}

.c-link {
  color: #005b99;
}

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: #1f1f1f;
}

.bg-aux {
  background-color: #707070;
}

.bg-pale {
  background-color: #dedede;
}

.bg-thin {
  background-color: #f2f3f6;
}

.bg-deep {
  background-color: #000409;
}

.bg-deep-thin {
  background-color: #0048a2;
}

.bg-cool {
  background-color: #00ace8;
}

.bg-cool-thin {
  background-color: #82dfff;
}

.bg-light {
  background-color: #c00;
}

.bg-light-thin {
  background-color: #ff6666;
}

.bg-key {
  background-color: #e3aa03;
}

.bg-key-thin {
  background-color: #fdde82;
}

/* スペース
--------------------------------------------- */
.mt-skin {
  margin-top: 10px !important;
}

.mt-min {
  margin-top: 20px !important;
}

.mt-xs {
  margin-top: 30px !important;
}

.mt-sm {
  margin-top: 40px !important;
}

@media only screen and (max-width: 768px) {
  .mt-sm {
    margin-top: 30px !important;
  }
}

.mt-md {
  margin-top: 60px !important;
}

@media only screen and (max-width: 768px) {
  .mt-md {
    margin-top: 40px !important;
  }
}

.mt-lg {
  margin-top: 80px !important;
}

@media only screen and (max-width: 768px) {
  .mt-lg {
    margin-top: 60px !important;
  }
}

.mt-xl {
  margin-top: 120px !important;
}

@media only screen and (max-width: 768px) {
  .mt-xl {
    margin-top: 80px !important;
  }
}

.mr-skin {
  margin-right: 10px !important;
}

.mr-min {
  margin-right: 20px !important;
}

.mr-xs {
  margin-right: 30px !important;
}

.ml-skin {
  margin-left: 10px !important;
}

.ml-min {
  margin-left: 20px !important;
}

.ml-xs {
  margin-left: 30px !important;
}

.ff {
  font-family: "Noto Sans JP", sans-serif !important;
}

.ff-min {
  font-family: "Noto Serif JP", serif !important;
}

.fw-b {
  font-weight: bold !important;
}

.fw-n {
  font-weight: normal !important;
}

.fs-min {
  font-size: 72% !important;
}

.fs-xs {
  font-size: 86% !important;
}

.fs-sm {
  font-size: 93% !important;
}

.fs-md {
  font-size: 115% !important;
}

.fs-lg {
  font-size: 143% !important;
}

.fs-xl {
  font-size: 172% !important;
}

.fs10 {
  font-size: 1.0rem !important;
}

.fs11 {
  font-size: 1.1rem !important;
}

.fs12 {
  font-size: 1.2rem !important;
}

.fs13 {
  font-size: 1.3rem !important;
}

.fs14 {
  font-size: 1.4rem !important;
}

.fs15 {
  font-size: 1.5rem !important;
}

.fs16 {
  font-size: 1.6rem !important;
}

.fs18 {
  font-size: 1.8rem !important;
}

.fs20 {
  font-size: 2.0rem !important;
}

.fs24 {
  font-size: 2.4rem !important;
}

.fs28 {
  font-size: 2.8rem !important;
}

.fs30 {
  font-size: 3.0rem !important;
}

.fs36 {
  font-size: 3.6rem !important;
}

.fs40 {
  font-size: 4.0rem !important;
}

.fs48 {
  font-size: 4.8rem !important;
}

.lh10 {
  line-height: 1.0 !important;
}

.lh12 {
  line-height: 1.2 !important;
}

.lh13 {
  line-height: 1.3 !important;
}

.lh14 {
  line-height: 1.4 !important;
}

.lh15 {
  line-height: 1.5 !important;
}

.lh16 {
  line-height: 1.6 !important;
}

.lh18 {
  line-height: 1.8 !important;
}

.lh20 {
  line-height: 2.0 !important;
}

.lh30 {
  line-height: 3.0 !important;
}

.ta-l {
  text-align: left !important;
}

.ta-c {
  text-align: center !important;
}

.ta-r {
  text-align: right !important;
}

.ta-j {
  text-align: justify !important;
}

.ta-cl {
  text-align: center !important;
}

@media only screen and (max-width: 1000px) {
  .ta-cl {
    text-align: left !important;
  }
}

@media only screen and (max-width: 480px) {
  .xs-w90c {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

.xl-on {
  display: none;
}

@media only screen and (min-width: 1281px) {
  .xl-on {
    display: block;
  }
}

@media only screen and (min-width: 1281px) {
  .xl-no {
    display: none;
  }
}

.lg-on {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .lg-on {
    display: block;
  }
}

@media only screen and (max-width: 1280px) {
  .lg-no {
    display: none;
  }
}

.md-on {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .md-on {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .md-no {
    display: none;
  }
}

.sm-on {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sm-on {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .sm-no {
    display: none;
  }
}

.xs-on {
  display: none;
}

@media only screen and (max-width: 480px) {
  .xs-on {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .xs-no {
    display: none;
  }
}

a.line {
  text-decoration: underline;
}

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.d-table {
  display: table;
}

.d-tablec {
  display: table-cell;
}

.d-inline {
  display: inline;
}

.clearfix {
  display: block;
  min-height: 1%;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

* html .clearfix {
  height: 1%;
  /*\*/
  /*/
    height: auto;
    overflow: hidden;
    /**/
}

.clear {
  clear: both;
}

#container {
  position: relative;
}

#header {
  position: fixed;
  z-index: 10000;
  transition: .3s;
  top: 0;
  left: 0;
  width: 100%;
}

#header.is-active {
  display: none;
}

.site-nav {
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  width: 100%;
  height: 80px;
  transition: background-color .4s;
}

@media only screen and (max-width: 768px) {
  .site-nav {
    height: 60px;
  }
}

.is-animation .site-nav {
  height: 60px;
}

.is-animation .site-nav .site-nav--burger {
  bottom: 10px;
}

.is-animation .site-nav .site-nav--burger .site-nav--burger-text {
  display: none;
}

.is-animation .site-nav .site-nav--list {
  bottom: 13px;
}

.is-animation .site-nav .site-nav--logo {
  bottom: 10px;
}

.is-animation .site-nav .site-nav--logo svg {
  width: 140px;
}

.is-animation .site-nav .site-nav--tool {
  bottom: 13px;
}

.is-animation .site-nav .site-nav--tool .site-nav--anchor span {
  display: none;
}

.is-animation .site-nav .site-nav--tel {
  bottom: 18px;
}

.is-animation .site-nav .site-nav--tel-text {
  display: none;
}

.site-nav:hover,
.is-animation .site-nav {
  background-color: white;
}

.site-nav:hover .site-nav--burger .site-nav--burger-icon div,
.is-animation .site-nav .site-nav--burger .site-nav--burger-icon div {
  background-color: #1f1f1f;
}

.site-nav:hover .site-nav--burger .site-nav--burger-text,
.is-animation .site-nav .site-nav--burger .site-nav--burger-text {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--list .site-nav--item .site-nav--anchor,
.is-animation .site-nav .site-nav--list .site-nav--item .site-nav--anchor {
  color: #1f1f1f;
  transition: color .3s ease-in-out;
}

.site-nav:hover .site-nav--list .site-nav--item .site-nav--anchor:hover,
.is-animation .site-nav .site-nav--list .site-nav--item .site-nav--anchor:hover {
  color: #000409;
}

.site-nav:hover .site-nav--logo svg,
.is-animation .site-nav .site-nav--logo svg {
  fill: #1f1f1f;
}

.site-nav:hover .site-nav--tool .site-nav--item .site-nav--anchor .site-nav--anchor-icon i,
.is-animation .site-nav .site-nav--tool .site-nav--item .site-nav--anchor .site-nav--anchor-icon i {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--tool .site-nav--item .site-nav--anchor span,
.is-animation .site-nav .site-nav--tool .site-nav--item .site-nav--anchor span {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--tel-number i,
.is-animation .site-nav .site-nav--tel-number i {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--tel-number .number,
.is-animation .site-nav .site-nav--tel-number .number {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--tel-number .number a,
.is-animation .site-nav .site-nav--tel-number .number a {
  color: #1f1f1f;
}

.site-nav:hover .site-nav--tel-text,
.is-animation .site-nav .site-nav--tel-text {
  color: #1f1f1f;
}

.site-nav.is-animation {
  background-color: white;
}

.site-nav--burger {
  position: absolute;
  left: 0;
  bottom: 20px;
  margin-left: 40px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .site-nav--burger {
    bottom: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .site-nav--burger {
    margin-left: 20px;
  }
}

.site-nav--burger.active div:nth-of-type(2) {
  opacity: 0;
}

.site-nav--burger .site-nav--burger-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 16px;
}

.site-nav--burger .site-nav--burger-icon div {
  position: absolute;
  display: inline-block;
  transition: transform .3s;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.site-nav--burger .site-nav--burger-icon div:nth-of-type(1) {
  top: 0;
  -webkit-animation: burger-bar01 .75s forwards;
  animation: burger-bar01 .75s forwards;
}

.site-nav--burger .site-nav--burger-icon div:nth-of-type(2) {
  top: 7px;
  opacity: 1;
}

.site-nav--burger .site-nav--burger-icon div:nth-of-type(3) {
  bottom: 0;
  -webkit-animation: burger-bar03 .75s forwards;
  animation: burger-bar03 .75s forwards;
}

.site-nav--burger .site-nav--burger-text {
  display: block;
  font-size: 1.0rem;
  color: white;
  line-height: 1;
  font-weight: lighter;
}

@-webkit-keyframes burger-bar01 {
  0% {
    -webkit-transform: translateY(7px) rotate(45deg);
  }
  50% {
    -webkit-transform: translateY(7px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes burger-bar01 {
  0% {
    transform: translateY(7px) rotate(45deg);
  }
  50% {
    transform: translateY(7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@-webkit-keyframes burger-bar03 {
  0% {
    -webkit-transform: translateY(-7px) rotate(-45deg);
  }
  50% {
    -webkit-transform: translateY(-7px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes burger-bar03 {
  0% {
    transform: translateY(-7px) rotate(-45deg);
  }
  50% {
    transform: translateY(-7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.site-nav--list {
  position: absolute;
  display: flex;
  left: 110px;
  bottom: 14px;
}

.site-nav--list .site-nav--item {
  font-size: 1.2rem;
}

.site-nav--list .site-nav--item:nth-child(n+2) {
  margin-left: 20px;
}

.site-nav--list .site-nav--item .site-nav--anchor {
  color: white;
  font-weight: lighter;
  transition: color .3s ease-out;
}

@media only screen and (max-width: 1280px) {
  .site-nav--list .site-nav--item .site-nav--anchor {
    display: none;
  }
}

.site-nav--logo {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  line-height: 1.0;
}

@media only screen and (max-width: 768px) {
  .site-nav--logo {
    bottom: 14px;
  }
}

.site-nav--logo svg {
  fill: white;
  width: 226px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .site-nav--logo svg {
    width: 148px;
    height: 26px;
  }
}

.site-nav--tool {
  position: absolute;
  display: table;
  right: 0;
  bottom: 14px;
}

.site-nav--tool .site-nav--item {
  display: table-cell;
  padding-left: 20px;
  min-width: 40px;
}

.site-nav--tool .site-nav--item:last-child {
  padding-right: 30px;
}

.site-nav--tool .site-nav--item .site-nav--anchor {
  position: relative;
  display: inline-block;
}

.site-nav--tool .site-nav--item .site-nav--anchor .site-nav--anchor-icon {
  height: 16px;
  text-align: center;
}

.site-nav--tool .site-nav--item .site-nav--anchor .site-nav--anchor-icon i {
  color: white;
}

.site-nav--tool .site-nav--item .site-nav--anchor span {
  display: block;
  color: white;
  margin-top: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  line-height: 1;
}

.site-nav--tel {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .site-nav--tel {
    right: 20px;
    bottom: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .site-nav--tel {
    bottom: 22px;
  }
}

.site-nav--tel-number {
  line-height: 1.0;
}

.site-nav--tel-number i {
  color: white;
  margin-right: 8px;
  font-size: 2.0rem;
  line-height: 1.0;
}

@media only screen and (max-width: 768px) {
  .site-nav--tel-number i {
    margin-right: 4px;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  .site-nav--tel-number i {
    margin-right: 0;
    font-size: 2.0rem;
  }
}

.site-nav--tel-number .number {
  color: white;
  font-size: 2.4rem;
  line-height: 1.0;
  letter-spacing: .5px;
}

@media only screen and (max-width: 768px) {
  .site-nav--tel-number .number {
    font-size: 1.6rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 480px) {
  .site-nav--tel-number .number {
    display: none;
  }
}

.site-nav--tel-number .number a {
  color: white;
}

.site-nav--tel-text {
  color: white;
  font-size: 1.2rem;
  line-height: 1.0;
  margin-top: 5px;
}

@media only screen and (max-width: 480px) {
  .site-nav--tel-text {
    display: none;
  }
}

.breadcrumb {
  position: absolute;
  top: 100%;
  width: 100%;
  display: block;
}

.is-animation .breadcrumb {
  display: none;
}

.front .breadcrumb {
  display: none;
}

.breadcrumb--list {
  font-size: 0;
  margin: 0;
  padding: 0;
  padding-left: 40px;
}

@media only screen and (max-width: 768px) {
  .breadcrumb--list {
    padding: 0 5%;
  }
}

.breadcrumb--item {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  line-height: 1.2;
}

.breadcrumb--item:first-child:before {
  content: none;
}

.breadcrumb--item:before {
  content: '>';
  display: inline-block;
  margin: 0 10px;
  color: white;
}

@media only screen and (max-width: 768px) {
  .breadcrumb--item:before {
    margin: 0 5px;
  }
}

.breadcrumb--item a {
  color: white;
  transition: color .3s ease-in-out;
}

.breadcrumb--item a:hover {
  color: #000409;
}

.breadcrumb--item span {
  color: white;
}

#sec-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#sec-hero .slider {
  width: 100%;
  height: 100vh;
}

#sec-hero .slider .slide {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#sec-hero .slider .slide.slick-active .title-big {
  animation: hero 1s;
}

#sec-hero .slider .slide.slick-active .button {
  animation: hero 2s;
}

#sec-hero .slider .slide.slide1 {
  background-image: url(images/main1.jpg);
}

@media only screen and (max-width: 768px) {
  #sec-hero .slider .slide.slide1 {
    background-image: url(images/main1s.jpg);
  }
}

#sec-hero .slider .slide.slide2 {
  background-image: url(images/main2.jpg);
}

@media only screen and (max-width: 768px) {
  #sec-hero .slider .slide.slide2 {
    background-image: url(images/main2s.jpg);
  }
}

#sec-hero .sec-hero--item {
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#sec-hero .caption-hero {
  position: absolute;
  bottom: 18%;
  left: 10%;
}

@media only screen and (max-width: 480px) {
  #sec-hero .caption-hero {
    left: 5%;
  }
}

#sec-hero .caption-hero hgroup {
  display: flex;
  flex-direction: column-reverse;
}

#sec-hero .caption-hero .title-big {
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  font-weight: normal;
  line-height: 1.2;
  color: white;
  text-shadow: #1f1f1f 0 0 10px;
}

#sec-hero .caption-hero .title-big span {
  display: block;
  font-size: calc(30px + 30*(100vw - 320px)/1280);
}

#sec-hero .caption-hero .title {
  font-size: calc(30px + 30*(100vw - 320px)/1280);
  font-weight: normal;
  line-height: 1.2;
  color: white;
}

#sec-hero .caption-hero .text {
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  font-weight: normal;
  line-height: 1.2;
  color: white;
}

#sec-hero .caption-hero .button {
  margin-top: 40px;
}

#sec-hero .caption-hero .button:hover {
  border: 2px solid #000409;
  background-color: #000409;
  color: white;
}

#sec-hero a:hover .button {
  border: 2px solid white;
  background-color: white;
  color: #000409;
}

@keyframes hero {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sec-mv {
  position: relative;
  width: 100%;
  height: auto;
  background: center center / cover no-repeat;
  opacity: 0;
  transition: opacity 2s ease-in;
}

.sec-mv:before {
  display: block;
  padding-top: 50vh;
  content: '';
}

.sec-mv img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  z-index: -1;
}

.action .sec-mv {
  opacity: 1;
}

.action .sec-mv .block .title {
  opacity: 1;
  transform: translate(0, 0);
}

.action .sec-mv .block .sup {
  opacity: 1;
  transform: translate(0, 0);
}

.sec-mv .block {
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 1.2;
}

.sec-mv .block .title {
  font-size: calc(24px + 24*(100vw - 320px)/1280);
  font-weight: normal;
  line-height: 1.2;
  color: white;
  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
  font-family: "Noto Serif JP", serif;
}

.sec-mv .block .sup {
  font-size: calc(16px + 6*(100vw - 320px)/1280);
  line-height: 1.2;
  color: white;
  margin: 20px 0 0;
  padding: 20px 0 0;
  position: relative;
  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
}

.sec-mv .block .sup::before {
  display: block;
  content: '';
  width: 60px;
  height: 1px;
  background-color: #f2f3f6;
  top: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}

.sec-mv.block-reverse {
  flex-direction: column;
}

.sec-mv.block-reverse .sup {
  margin: 0 0 10px;
}

.heroscroll {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}

.heroscroll i {
  color: white;
  font-size: 4.0rem;
  animation: heroscroll 2s infinite;
}

@keyframes heroscroll {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.sec-book {
  width: 100%;
  padding: 80px 0;
  background-color: #f2f3f6;
}

@media only screen and (max-width: 768px) {
  .sec-book {
    padding: 40px 0;
  }
}

.sec-book--inner {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .sec-book--inner {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 768px) {
  .sec-book--inner {
    flex-flow: column nowrap;
  }
}

.sec-book--photo {
  width: 40%;
  flex: 0 1 40%;
}

@media only screen and (max-width: 768px) {
  .sec-book--photo {
    width: 100%;
    flex: 0 0 100%;
    text-align: center;
  }
}

.sec-book--photo img {
  width: 100%;
  height: auto;
  box-shadow: #dedede 5px 5px 10px;
}

@media only screen and (max-width: 768px) {
  .sec-book--photo img {
    width: 200px;
  }
}

.sec-book--detail {
  width: 60%;
  flex: 1 0 60%;
  padding: 0 0 0 10%;
  display: flex;
  flex-flow: column nowrap;
}

@media only screen and (max-width: 1000px) {
  .sec-book--detail {
    padding: 0 0 0 5%;
  }
}

@media only screen and (max-width: 768px) {
  .sec-book--detail {
    width: 100%;
    flex: 0 0 100%;
    margin-top: 30px;
    padding: 0;
  }
}

.sec-book--detail .title {
  order: 1;
  font-size: calc(20px + 10*(100vw - 320px)/1280);
  font-weight: 700;
  line-height: 1.4;
  text-align: justify;
}

.sec-book--detail .box {
  order: 3;
  margin-top: 20px;
  text-align: justify;
}

.sec-book--detail .box p + p {
  margin-top: 20px;
}

.sec-book--detail .copy {
  order: 4;
  margin-top: 10px;
  text-align: right;
}

.sec-column {
  width: 100%;
}

.sec-column--title {
  position: relative;
  height: 320px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .sec-column--title {
    height: 240px;
  }
}

.sec-column--title .title {
  font-size: calc(18px + 12*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: 5px;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  display: block;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 480px) {
  .sec-column--title .title {
    font-weight: 500;
  }
}

.sec-column--pic {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  z-index: -1;
}

.sec-column.sec-column-top .sec-column--inner {
  margin-top: -100px;
  background-color: white;
  padding: 60px;
}

@media only screen and (max-width: 1280px) {
  .sec-column.sec-column-top .sec-column--inner {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .sec-column.sec-column-top .sec-column--inner {
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .sec-column.sec-column-top .sec-column--inner {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 480px) {
  .sec-column.sec-column-top .sec-column--inner {
    padding: 20px;
  }
}

.sec-column--inner {
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .sec-column--inner {
    max-width: 90%;
  }
}

.sec-column--list {
  display: flex;
  flex-flow: row wrap;
  margin: -50px 0 0 -30px;
}

.sec-column--item {
  padding: 50px 0 0 30px;
  flex: 0 0 auto;
  width: 33%;
  line-height: 1.4;
}

@media only screen and (max-width: 1000px) {
  .sec-column--item {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .sec-column--item {
    flex: 0 0 auto;
    width: 100%;
  }
}

.sec-column--item a {
  color: #1f1f1f;
  transition: color .3s ease-in-out;
}

.sec-column--item a:hover {
  color: #000409;
}

.sec-column--item .toggle {
  cursor: pointer;
}

.sec-column--item .toggle:hover span {
  color: #000409;
}

.sec-column--item .toggle:hover span:after {
  border-top: 4px solid #000409;
}

.sec-column--item .toggle span {
  position: relative;
  display: block;
  line-height: 1.4;
  font-weight: 700;
  color: #1f1f1f;
  transition: color .3s ease-in-out;
  padding: 20px 20px 20px 10px;
  border-top: 1px solid #dedede;
}

.sec-column--item .toggle span:after {
  position: absolute;
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  border: 4px solid transparent;
  border-top: 4px solid #1f1f1f;
  margin-left: 5px;
  vertical-align: middle;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}

.sec-column--item .toggle.active span:after {
  border-bottom: 4px solid #1f1f1f;
  border-top: none;
}

.sec-column--item .child {
  padding: 0 0 0 10px;
  font-size: calc(14px + 2*(100vw - 320px)/1280);
  line-height: 1.4;
}

.sec-column--item .child.hide {
  display: none;
}

.sec-column--item .child--item:not(:first-child) {
  margin-top: 10px;
}

.sec-time .table-simple thead {
  background-color: #e3aa03;
}

.sec-time .table-simple td {
  width: 10%;
  min-width: 65px;
}

.sec-form {
  background-color: #f2f3f6;
  padding: 100px 5%;
}

@media only screen and (max-width: 768px) {
  .sec-form {
    padding: 60px 5%;
  }
}

.sec-form--title {
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  text-align: center;
}

.sec-form--text {
  margin-top: 20px;
  text-align: center;
}

.sec-form--inner {
  max-width: 960px;
  margin: 40px auto 0;
}

@media only screen and (max-width: 768px) {
  .sec-form--inner {
    margin: 20px auto 0;
  }
}

#mailform .mailform {
  width: 100%;
  border-collapse: collapse;
  line-height: 1.3;
  border-top: 1px solid #dedede;
}

@media only screen and (max-width: 768px) {
  #mailform .mailform {
    display: block;
  }
}

#mailform tbody {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #mailform tbody {
    display: block;
  }
}

#mailform tr {
  width: 100%;
  border-bottom: 1px solid #dedede;
}

@media only screen and (max-width: 768px) {
  #mailform tr {
    display: block;
  }
}

#mailform th {
  position: relative;
  width: 35%;
  padding: 16px 0 16px 32px;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  #mailform th {
    display: block;
    width: 100%;
    padding: 16px 0 0 0;
  }
}

#mailform th div {
  position: relative;
}

#mailform th span {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 4px;
  transform: translate(0, -50%);
  color: #c00;
  font-weight: normal;
  line-height: 1em;
}

@media only screen and (max-width: 768px) {
  #mailform th span {
    position: relative;
    display: inline-block;
    transform: translate(0, 0);
    padding: 0 0 0 4px;
  }
}

#mailform td {
  width: 65%;
  padding: 16px 0 16px 32px;
  vertical-align: middle;
  line-height: 1.8;
}

@media only screen and (max-width: 768px) {
  #mailform td {
    display: block;
    width: 100%;
    padding: 10px 0 16px 0;
  }
}

#mailform td.post input[type="text"] {
  width: 40%;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  #mailform td.birth select {
    padding: 10px 2px;
  }
}

#mailform td input[type="text"] {
  width: 100%;
  padding: 10px;
  border: none;
  font-family: "Noto Sans JP", sans-serif;
}

#mailform td textarea {
  width: 100%;
  padding: 10px;
  min-height: 200px;
  border: none;
  font-family: "Noto Sans JP", sans-serif;
}

#mailform td select {
  padding: 10px;
  border: none;
  font-family: "Noto Sans JP", sans-serif;
}

#mailform td select:not(:first-child) {
  margin-left: 10px;
}

#mailform .mailcheck {
  text-align: center;
}

#mailform .mailsend {
  text-align: center;
}

#mailform .mailsend input[type="submit"] {
  display: inline-block;
  min-width: 240px;
  padding: 12px 16px;
  background: #000409;
  border: 1px solid #000409;
  color: white;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  transition: .3s;
}

#mailform .mailsend input[type="submit"]:hover {
  background: white;
  color: #000409;
}

#mailform .mailsend input[type="reset"] {
  display: inline-block;
  min-width: 160px;
  padding: 8px 12px;
  background: #dedede;
  border: 1px solid #dedede;
  color: #1f1f1f;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  transition: .3s;
  margin-top: 20px;
}

#mailform .mailsend input[type="reset"]:hover {
  background: white;
}

.sec-full {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  position: relative;
  min-height: 60vh;
  max-height: 60vh;
}

@media only screen and (max-width: 768px) {
  .sec-full {
    flex-flow: column nowrap;
    min-height: inherit;
    max-height: inherit;
  }
}

.sec-full:before {
  display: block;
  padding-top: 100%;
  content: '';
}

@media only screen and (max-width: 768px) {
  .sec-full:before {
    content: none;
  }
}

.sec-full--pic img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 768px) {
  .sec-full--pic img {
    position: static;
  }
}

.sec-full--box {
  width: 50%;
  margin-left: auto;
  z-index: 10;
  padding-left: 10%;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .sec-full--box {
    width: 100%;
    margin-bottom: 40px;
    padding: 0 5%;
  }
}

.sec-full--title {
  font-size: calc(20px + 20*(100vw - 320px)/1280);
  font-weight: 500;
}

.sec-full--text {
  font-size: calc(16px + 8*(100vw - 320px)/1280);
  margin-top: 20px;
}

.sec-half {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .sec-half {
    flex-flow: column nowrap;
    min-height: inherit;
    max-height: inherit;
  }
}

.sec-half.sec-half-reverse {
  flex-flow: row-reverse nowrap;
}

@media only screen and (max-width: 768px) {
  .sec-half.sec-half-reverse {
    flex-flow: column nowrap;
  }
}

.sec-half--pic {
  position: relative;
  width: 50%;
  flex: 0 1 50%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .sec-half--pic {
    width: 100%;
    flex: 0 0 100%;
  }
}

.sec-half--pic:before {
  display: block;
  padding-top: 100%;
  content: '';
}

.sec-half--pic img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.sec-half--detail {
  width: 50%;
  flex: 1 0 50%;
  padding: 0 5%;
}

@media only screen and (max-width: 768px) {
  .sec-half--detail {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 40px;
  }
}

.sec-half--box-sm {
  width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.sec-half--title {
  font-size: calc(20px + 20*(100vw - 320px)/1280);
  font-weight: 500;
}

.sec-half--text {
  font-size: calc(16px + 8*(100vw - 320px)/1280);
  margin-top: 20px;
}

.sec-carrer2 img {
  object-position: 80% 50%;
}

.sec-carrer2 .table-simple.t-noborder th,
.sec-carrer2 .table-simple.t-noborder td {
  padding: 5px 0;
}

.footer {
  padding: 80px 0 0;
  margin: 80px 0 0;
  background-color: #000409;
  color: #f2f3f6;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 60px 0 0;
  }
}

.footer-inner {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .footer-inner {
    padding: 0 5%;
  }
}

.footer-info {
  text-align: center;
  line-height: 1.3;
}

.footer-info--title {
  font-size: 1.4rem;
}

.footer-info--title span {
  display: block;
  font-size: 2.2rem;
}

@media only screen and (max-width: 768px) {
  .footer-info--title span {
    font-size: 2.4rem;
  }
}

.footer-info--address {
  font-style: normal;
  margin-top: 10px;
}

.footer-info--address span {
  display: block;
  font-size: 1.4rem;
}

.footer-info--tel {
  margin-top: 20px;
  font-size: calc(20px + 4*(100vw - 320px)/1280);
  line-height: 1.3;
  letter-spacing: 5px;
  font-weight: 700;
  color: #e3aa03;
}

@media only screen and (max-width: 768px) {
  .footer-info--tel {
    letter-spacing: 2px;
  }
}

.footer-info--tel span {
  display: block;
  font-size: calc(14px + 4*(100vw - 320px)/1280);
  letter-spacing: 0;
  font-weight: 400;
}

.footer-info--tel a {
  color: #e3aa03;
  text-decoration: none;
}

.footer-time .table-simple {
  width: inherit;
  margin: 0 auto;
  border-top: 1px solid #dedede;
}

.footer-time .table-simple thead {
  background-color: inherit;
  border-bottom: 1px solid #dedede;
}

.footer-time .table-simple tbody {
  border-bottom: 1px solid #dedede;
}

.footer-time .table-simple th, .footer-time .table-simple td {
  padding: 5px 0;
  font-size: 1.4rem;
}

.footer-time .table-simple th span, .footer-time .table-simple td span {
  font-size: 1.2rem;
}

.footer-time .table-simple td {
  width: 40px;
}

.footer-copyright {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
  color: #707070;
}

.footer-totop {
  position: relative;
  width: 90%;
  margin: 40px auto 0;
  padding: 30px 0;
  border-top: 1px solid black;
  display: block;
}

@media only screen and (max-width: 768px) {
  .footer-totop {
    width: 100%;
  }
}

.footer-totop:before {
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  transform: rotate(-45deg) translate(-50%, -50%);
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.drawermenu {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 40vw;
  height: 100vh;
  overflow: auto;
  background-color: #000409;
  transition: opacity .3s ease-out, display .3s ease-out;
  padding: 30px 40px;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 768px) {
  .drawermenu {
    width: 80vw;
  }
}

@media only screen and (max-width: 480px) {
  .drawermenu {
    padding: 30px 20px;
  }
}

.drawermenu.hide {
  display: none;
}

.drawermenu.is-active {
  opacity: 1;
  display: block;
  z-index: 2000;
}

.drawermenu .drawermenu--burger-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.drawermenu .drawermenu--burger-icon div {
  position: absolute;
  display: inline-block;
  transition: transform .3s;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: white;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(1) {
  top: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
  -webkit-animation: drawer-bar01 .75s forwards;
  animation: drawer-bar01 .75s forwards;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(2) {
  opacity: 0;
}

.drawermenu .drawermenu--burger-icon div:nth-of-type(3) {
  top: 50%;
  transform: rotate(-45deg) translate(-50%, -50%);
  -webkit-animation: drawer-bar03 .75s forwards;
  animation: drawer-bar03 .75s forwards;
}

.drawermenu--logo {
  display: block;
  text-align: center;
  margin-top: -32px;
}

.drawermenu--logo-anchor {
  display: inline-block;
  margin: 0 auto;
}

.drawermenu--logo svg {
  width: 160px;
  height: auto;
  max-height: 40px;
  fill: white;
}

@media only screen and (max-width: 768px) {
  .drawermenu--logo svg {
    width: 140px;
  }
}

.drawermenu .swiper-slide {
  margin: 0;
}

.drawermenu .swiper-button-prev,
.drawermenu .swiper-button-next {
  display: none;
}

.drawermenu .swiper-pagination-drawermenu {
  text-align: center;
}

.drawermenu .swiper-pagination-drawermenu .swiper-pagination-bullet {
  width: 20px;
  height: 2px;
  border-radius: 0;
}

.drawermenu .swiper-pagination-drawermenu .swiper-pagination-bullet-active {
  background: #e3aa03;
}

@-webkit-keyframes drawer-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(45deg);
  }
}

@keyframes drawer-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(45deg);
  }
}

@-webkit-keyframes drawer-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(0) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
  }
}

@keyframes drawer-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.card-drawermenu--anchor {
  display: block;
  position: relative;
}

.card-drawermenu--anchor .caption {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.card-drawermenu--anchor .caption .title {
  font-size: 1.2rem;
  text-align: center;
  color: white;
  line-height: 1.3;
}

.drawer-nav--section {
  border-top: 1px solid #707070;
  padding-top: 20px;
}

.drawer-nav--section-first {
  border-top: none;
  padding-top: 0;
}

.drawer-nav--section .address {
  font-size: 1.4rem;
  line-height: 1.4;
}

.drawer-nav--section .address span {
  font-size: 1.2rem;
  display: block;
}

.drawer-nav--section .tel {
  font-size: 2.0rem;
  font-weight: 700;
  line-height: 1.3;
  color: #e3aa03;
}

.drawer-nav--section .tel a {
  color: #e3aa03;
}

.drawer-nav--section .tel span {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
}

.drawer-nav--title {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.drawer-nav--title a {
  color: #dedede;
  transition: color .3s ease-in-out;
}

.drawer-nav--title a:hover {
  color: #e3aa03;
}

.drawer-nav--item {
  line-height: 1.4;
}

.drawer-nav--item:not(:first-child) {
  margin-top: 10px;
}

.drawer-nav--item a {
  color: white;
  font-size: 1.6rem;
  transition: color .3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .drawer-nav--item a {
    font-size: 1.4rem;
  }
}

.drawer-nav--item a:hover {
  color: #e3aa03;
}

.drawer-nav--item .toggle {
  cursor: pointer;
}

.drawer-nav--item .toggle:hover span {
  color: #e3aa03;
}

.drawer-nav--item .toggle:hover span:after {
  border-top: 4px solid #e3aa03;
}

.drawer-nav--item .toggle span {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.3;
  color: white;
  transition: color .3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .drawer-nav--item .toggle span {
    font-size: 1.4rem;
  }
}

.drawer-nav--item .toggle span:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  border: 4px solid transparent;
  border-top: 4px solid white;
  margin-left: 5px;
  vertical-align: middle;
}

.drawer-nav--item .toggle.active span {
  color: #e3aa03;
}

.drawer-nav--item .toggle.active span:after {
  border-bottom: 4px solid #e3aa03;
  border-top: none;
}

.drawer-nav--item .child {
  padding-left: 20px;
  margin: 0 0 10px;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .drawer-nav--item .child {
    padding-left: 10px;
  }
}

.drawer-nav--item .child.hide {
  display: none;
}

.drawer-nav--item .child--item {
  margin-top: 10px;
}

.page-cover {
  display: none;
}

.page-cover.is-active {
  display: block;
  z-index: 1900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.sec-contentbottom {
  margin-top: 160px;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .sec-contentbottom {
    max-width: 80%;
  }
}

@media only screen and (max-width: 480px) {
  .sec-contentbottom {
    margin-top: 80px;
    max-width: 90%;
  }
}

.sec-contentbottom--title {
  font-size: calc(18px + 12*(100vw - 320px)/1280);
  text-align: center;
}

.sec-contentbottom .swiper-contentbottom {
  padding: 0;
}

.sec-contentbottom .swiper-slide {
  margin: 0;
}

.sec-contentbottom .swiper-pagination-contentbottom {
  text-align: center;
}

.sec-contentbottom .swiper-pagination-contentbottom .swiper-pagination-bullet {
  width: 20px;
  height: 2px;
  border-radius: 0;
}

.sec-contentbottom .swiper-pagination-contentbottom .swiper-pagination-bullet-active {
  background: #e3aa03;
}

.card-contentbottom .caption {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .card-contentbottom .caption {
    margin-top: 10px;
  }
}

.card-contentbottom .caption .title {
  font-size: calc(14px + 4*(100vw - 320px)/1280);
  line-height: 1.3;
  font-weight: normal;
  color: #1f1f1f;
}

.card-contentbottom .caption .title span {
  font-weight: bold;
  display: block;
}

.slick-dots {
  bottom: 10vh;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
}

.slick-dots li {
  width: 40%;
  height: 3px;
}

.slick-dots li.slick-active {
  background-color: #fff;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  left: 0;
  -webkit-animation: slick-bar 5s linear;
  animation: slick-bar 5s linear;
  background-color: #e3aa03;
}

.slick-dots li button:before {
  content: '';
  background-color: #1f1f1f;
  opacity: 0.4;
  width: 100%;
  height: 3px;
}

@-webkit-keyframes slick-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slick-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.slider:hover .slick-next {
  right: 3%;
}

.slider:hover .slick-prev {
  left: 3%;
}

.slick-next {
  right: -50px;
  transition: right .3s ease-in-out;
}

.slick-next:hover:before {
  opacity: 1;
}

.slick-prev {
  left: -50px;
  transition: left .3s ease-in-out;
  z-index: 1;
}

.slick-prev:hover:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: calc(20px + 20*(100vw - 320px)/1280);
  opacity: .6;
  transition: opacity .3s ease-out;
  text-shadow: #707070 0 0 10px;
}

.swiper-container {
  padding: 0 10%;
}

@media only screen and (max-width: 768px) {
  .swiper-container {
    padding: 0 5%;
  }
}

.swiper-container.swiper-hero {
  padding: 0;
}

.swiper-container.swiper-hero .card {
  min-height: 100vh;
  max-height: 100vh;
}

.swiper-container .swiper-slide {
  flex: 0 0 auto;
  margin: 0 auto;
}

.swiper-container .swiper-slide.card-title {
  min-width: 45%;
  max-width: 45%;
  width: 45%;
}

@media only screen and (max-width: 1280px) {
  .swiper-container .swiper-slide.card-title {
    min-width: 50%;
    max-width: 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  .swiper-container .swiper-slide.card-title {
    min-width: 70%;
    max-width: 70%;
    width: 70%;
  }
}

.swiper-container .swiper-slide.card-lg {
  min-width: 80%;
  max-width: 80%;
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .swiper-container .swiper-slide.card-lg {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.swiper-container .swiper-slide.card-md {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .swiper-container .swiper-slide.card-md {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .swiper-container .swiper-slide.card-md {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.swiper-container .swiper-slide.card-sm {
  min-width: 30%;
  max-width: 30%;
  width: 30%;
}

@media only screen and (max-width: 1000px) {
  .swiper-container .swiper-slide.card-sm {
    min-width: 50%;
    max-width: 50%;
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .swiper-container .swiper-slide.card-sm {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .swiper-container .swiper-slide.card-sm {
    min-width: 80%;
    max-width: 80%;
    width: 80%;
  }
}

.swiper-container .swiper-slide.card-sm .card {
  min-height: 30vh;
  max-height: 30vh;
  height: 30vh;
}

.swiper-container a.card:hover .pic {
  transform: scale(1.05);
}

.swiper-container .card {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-flow: column nowrap;
}

.swiper-container .card .pic {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  transition: transform .3s;
}

.swiper-container .card .caption-title {
  position: absolute;
  left: 5vw;
  bottom: 10%;
}

.swiper-container .card .caption-title .title {
  color: white;
  font-size: calc(18px + 22*(100vw - 320px)/1280);
  line-height: 1.3;
  text-shadow: #1f1f1f 0 0 10px;
  font-weight: 500;
}

.swiper-container .card .caption-title .text {
  color: white;
  font-size: calc(14px + 4*(100vw - 320px)/1280);
  line-height: 1.4;
  margin-top: 10px;
  text-shadow: #1f1f1f 0 0 10px;
}

.swiper-container .card .caption-title .button {
  margin-top: 20px;
}

.swiper-container .card .caption-headline-item {
  padding: 20px 5px 0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.swiper-container .card .caption-headline-item .title {
  font-weight: normal;
  color: white;
  font-size: calc(18px + 4*(100vw - 320px)/1280);
  line-height: 1.3;
  text-shadow: #1f1f1f 0 0 5px;
}

.swiper-container .card .caption-number {
  padding-right: 5%;
  width: 100%;
}

.swiper-container .card .caption-number hgroup {
  display: flex;
  flex-direction: column;
}

.swiper-container .card .caption-number .number {
  font-size: 4.0rem;
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 40px;
}

.swiper-container .card .caption-number .title {
  font-size: calc(16px + 22*(100vw - 320px)/1280);
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 40px;
}

.swiper-container .card .caption-number .text {
  text-align: justify;
}

.swiper-container .card .caption-category hgroup {
  display: flex;
  flex-direction: column;
}

.swiper-container .card .caption-category .title {
  font-size: 1.4rem;
  line-height: 1.3;
  padding-bottom: 60px;
}

.swiper-container .card .caption-category .detail {
  font-size: 3.0rem;
  line-height: 1.3;
  padding-bottom: 60px;
}

.swiper-container .card .caption-text {
  padding: 0 5%;
}

.swiper-container .card .caption-desc {
  width: 100%;
  background-color: white;
  padding: 10px;
}

.swiper-container .card .caption-desc .text {
  font-size: calc(16px - 2*(100vw - 320px)/1280);
  line-height: 1.3;
}

.swiper-container:hover .swiper-button-prev {
  left: 5%;
}

.swiper-container:hover .swiper-button-next {
  right: 5%;
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  margin-top: -5%;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  opacity: .6;
  filter: drop-shadow(0 0 10px #707070);
}

@media only screen and (max-width: 1000px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 20px;
    height: 20px;
  }
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev {
  left: -20%;
  background: url(images/i-arrow-left.svg) no-repeat center center/cover;
}

.swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiper-button-next {
  right: -20%;
  background: url(images/i-arrow-right.svg) no-repeat center center/cover;
}

.swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  bottom: 0;
  border-radius: 0;
  width: 90%;
  left: 5%;
  position: relative;
  padding: 30px 0;
  height: 2px;
  background: transparent 50%/2px url(images/bg-swiper-scrollbar.png) repeat-x !important;
}

@media only screen and (max-width: 768px) {
  .swiper-container-horizontal > .swiper-scrollbar {
    padding: 20px 0;
  }
}

.swiper-container-horizontal > .swiper-scrollbar:hover .swiper-scrollbar-drag:after {
  height: 6px;
  top: -3px;
  border-radius: 10px;
}

.swiper-container-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #e3aa03;
  border-radius: 0;
}

.swiper-container-horizontal > .swiper-scrollbar .swiper-scrollbar-drag:after {
  background: #e3aa03;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
  transition: height .2s ease-out, top .2s ease-in-out, border-radius .2s ease-in-out;
}
