/*
Theme Name: dental
Theme URI:
Description: dental
Version: 1.0
Author:コウベビューティー株式会社
Author URI: https://kobe-beauty.co.jp/
Tags: one-column
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
@charset "UTF-8";

@import "_reset.scss";
@import "_aos-core.scss";
@import "_aos-easing.scss";
@import "_aos-animations.scss";
@import "_slick.scss";
@import "_slick-theme.scss";
@import "_mixin.scss";
@import "_setting.scss";
@import "_module.scss";


#container {
    position: relative;
}

// ----------------------------------------
// ヘッダー
// ----------------------------------------
#header {
    position: fixed;
    z-index: 10000;
    transition: .3s;
    top: 0;
    left: 0;
    width: 100%;
    &.is-active {
        display: none;
    }
}
.site-nav {
    background-color: rgba( 255, 255, 255, 0);
    position: relative;
    width: 100%;
    height: 80px;
    transition: background-color .4s;
    @include media(sm) {
        height: 60px;
    }
    .is-animation & {
        height: 60px;
        .site-nav--burger {
            bottom: 10px;
            .site-nav--burger-text {
                display: none;
            }
        }
        .site-nav--list {
            bottom: 13px;
        }
        .site-nav--logo {
            bottom: 10px;
            svg {
                width: 140px;
            }
        }
        .site-nav--tool {
            bottom: 13px;
            .site-nav--anchor {
                span {
                    display: none;
                }
            }
        }
        .site-nav--tel {
            bottom: 18px;
            &-text {
                display: none;
            }
        }
    }
    &:hover,
    .is-animation & {
        background-color: rgba( 255, 255, 255, 1);
        .site-nav--burger {
            .site-nav--burger-icon {
                div {
                    background-color: $black;
                }
            }
            .site-nav--burger-text {
                color: $black;
            }
        }
        .site-nav--list {
            .site-nav--item {
                .site-nav--anchor {
                    color: $black;
                    transition: color .3s ease-in-out;
                    &:hover {
                        color: $main;
                    }
                }
            }
        }
        .site-nav--logo {
            svg {
                fill: $black;
            }
        }
        .site-nav--tool {
            .site-nav--item {
                .site-nav--anchor {
                    .site-nav--anchor-icon {
                        i {
                            color: $black;
                        }
                    }
                    span {
                        color: $black;
                    }
                }
            }
        }
        .site-nav--tel {
            &-number {
                i {
                    color: $black;
                }
                .number {
                    color: $black;
                    a {
                        color: $black;
                    }
                }
            }
            &-text {
                color: $black;
            }
        }
    }
    &.is-animation {
        background-color: rgba( 255, 255, 255, 1);
    }
}
.site-nav--burger {
    position: absolute;
    left: 0;
    bottom: 20px;
    margin-left: 40px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    @include media(sm) {
        bottom: 12px;
    }
    @include media(xs) {
        margin-left: 20px;
    }
    &.active {
        div {
            &:nth-of-type(2) {
                opacity: 0;
            }
         }
    }
    .site-nav--burger-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 16px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: white;
            &:nth-of-type(1) {
                top: 0;
                -webkit-animation: burger-bar01 .75s forwards;
                animation: burger-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                top: 7px;
                opacity: 1;
            }
            &:nth-of-type(3) {
                bottom: 0;
                -webkit-animation: burger-bar03 .75s forwards;
                animation: burger-bar03 .75s forwards;
            }
        }
    }
    .site-nav--burger-text {
        display: block;
        font-size: 1.0rem;
        color: white;
        line-height: 1;
        font-weight: lighter;
    }
}
@-webkit-keyframes burger-bar01 {
    0% { -webkit-transform: translateY(7px) rotate(45deg); }
    50% { -webkit-transform: translateY(7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar01 {
    0% { transform: translateY(7px) rotate(45deg); }
    50% { transform: translateY(7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}
@-webkit-keyframes burger-bar03 {
    0% { -webkit-transform: translateY(-7px) rotate(-45deg); }
    50% { -webkit-transform: translateY(-7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar03 {
    0% { transform: translateY(-7px) rotate(-45deg); }
    50% { transform: translateY(-7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}

.site-nav--list {
    position: absolute;
    display: flex;
    left: 110px;
    bottom: 14px;
    .site-nav--item {
        font-size: 1.2rem;
        &:nth-child(n+2) {
            margin-left: 20px;
        }
        .site-nav--anchor {
            color: white;
            font-weight: lighter;
            transition: color .3s ease-out;
            @include media(lg) {
                display: none;
            }
        }
    }
}

.site-nav--logo {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    line-height: 1.0;
    @include media(sm) {
        bottom: 14px;
    }
    svg {
        fill: white;
        width: 226px;
        height: 40px;
        @include media(sm) {
            width: 148px;
            height: 26px;
        }
    }
}

.site-nav--tool {
    position: absolute;
    display: table;
    right: 0;
    bottom: 14px;
    .site-nav--item {
        display: table-cell;
        padding-left: 20px;
        min-width: 40px;
        &:last-child {
            padding-right: 30px;
        }
        .site-nav--anchor {
            position: relative;
            display: inline-block;
            .site-nav--anchor-icon {
                height: 16px;
                text-align: center;
                i {
                    color: white;
                }
            }
            span {
                display: block;
                color: white;
                margin-top: 10px;
                text-align: center;
                font-size: 1.2rem;
                font-weight: lighter;
                line-height: 1;
            }
        }
    }
}

.site-nav--tel {
    position: absolute;
    right: 30px;
    bottom: 20px;
    @include media(sm) {
        right: 20px;
        bottom: 16px;
    }
    @include media(xs) {
        bottom: 22px;
    }
    &-number {
        line-height: 1.0;
        i {
            color: white;
            margin-right: 8px;
            font-size: 2.0rem;
            line-height: 1.0;
            @include media(sm) {
                margin-right: 4px;
                font-size: 1.4rem;
            }
            @include media(xs) {
                margin-right: 0;
                font-size: 2.0rem;
            }
        }
        .number {
            color: white;
            font-size: 2.4rem;
            line-height: 1.0;
            letter-spacing: .5px;
            @include media(sm) {
                font-size: 1.6rem;
                font-weight: bold;
            }
            @include media(xs) {
                display: none;
            }
            a {
                color: white;
            }
        }
    }
    &-text {
        color: white;
        font-size: 1.2rem;
        line-height: 1.0;
        margin-top: 5px;
        @include media(xs) {
            display: none;
        }
    }
}


.breadcrumb {
    position: absolute;
    top: 100%;
    width: 100%;
    display: block;
    .is-animation & {
        display: none;
    }
    .front & {
        display: none;
    }
    &--list {
        font-size: 0;
        margin: 0;
        padding: 0;
        padding-left: 40px;
        @include media(sm) {
            padding: 0 5%;
        }
    }
    &--item {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.2rem;
        line-height: 1.2;
        &:first-child:before {
            content: none;
        }
        &:before {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            color: white;
            @include media(sm) {
                margin: 0 5px;
            }
        }
        a {
            color: white;
            transition: color .3s ease-in-out;
            &:hover {
                color: $main;
            }
        }
        span {
            color: white;
        }
    }
}

// ----------------------------------------
// メイン
// ----------------------------------------
#sec-hero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .slider {
        width: 100%;
        height: 100vh;
        .slide {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            &.slick-active {
                .title-big {
                    animation: hero 1s;
                }
                .button {
                    animation: hero 2s;
                }
            }
            &.slide1 {
                background-image: url(images/main1.jpg);
                @include media(sm) {
                    background-image: url(images/main1s.jpg);
                }
            }
            &.slide2 {
                background-image: url(images/main2.jpg);
                @include media(sm) {
                    background-image: url(images/main2s.jpg);
                }
            }
        }
    }
    .sec-hero--item {
        width: 100%;
        height: 100vh;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .caption-hero {
        position: absolute;
        bottom: 18%;
        left: 10%;
        @include media(xs) {
            left: 5%;
        }
        hgroup {
            display: flex;
            flex-direction: column-reverse;
        }
        .title-big {
            font-size: calc(18px + 22*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
            text-shadow: $black 0 0 10px;
            span {
                display: block;
                font-size: calc(30px + 30*(100vw - 320px)/1280);
            }
        }
        .title {
            font-size: calc(30px + 30*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
        }
        .text {
            font-size: calc(18px + 22*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
        }
        .button {
            margin-top: 40px;
            &:hover {
                border: 2px solid $main;
                background-color: $main;
                color: white;
            }
        }
    }
    a:hover .button {
        border: 2px solid white;
        background-color: white;
        color: $main;
    }
}
@keyframes hero {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



.sec-mv {
    position: relative;
    width: 100%;
    height: auto;
    background: center center / cover no-repeat;
    opacity: 0;
    transition: opacity 2s ease-in;
    &:before {
        display: block;
        padding-top: 50vh;
        content: '';
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
    .action & {
        opacity: 1;
        .block {
            .title {
                opacity: 1;
                transform: translate(0, 0);
            }
            .sup {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    .block {
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        line-height: 1.2;
        .title {
            font-size: calc(24px + 24*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
            font-family: $font-min;
        }
        .sup {
            font-size: calc(16px + 6*(100vw - 320px)/1280);
            line-height: 1.2;
            color: white;
            margin: 20px 0 0;
            padding: 20px 0 0;
            position: relative;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
            &::before {
                display: block;
                content: '';
                width: 60px;
                height: 1px;
                background-color: $thin;
                top: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 0);
            }
        }
    }
    &.block-reverse {
        flex-direction: column;
        .sup {
            margin: 0 0 10px;
        }
    }
}


.heroscroll {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 0);
    i {
        color: white;
        font-size: 4.0rem;
        animation: heroscroll 2s infinite;
    }
}
@keyframes heroscroll {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}


// top index
.sec-book {
    width: 100%;
    padding: 80px 0;
    background-color: $thin;
    @include media(sm) {
        padding: 40px 0;
    }
    &--inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        @include media(md) {
            padding: 0 5%;
        }
        @include media(sm) {
            flex-flow: column nowrap;
        }
    }
    &--photo {
        width: 40%;
        flex: 0 1 40%;
        @include media(sm) {
            width: 100%;
            flex: 0 0 100%;
            text-align: center;
        }
        img {
            width: 100%;
            height: auto;
            box-shadow: $pale 5px 5px 10px;
            @include media(sm) {
                width: 200px;
            }
        }
    }
    &--detail {
        width: 60%;
        flex: 1 0 60%;
        padding: 0 0 0 10%;
        display: flex;
        flex-flow: column nowrap;
        @include media(md) {
            padding: 0 0 0 5%;
        }
        @include media(sm) {
            width: 100%;
            flex: 0 0 100%;
            margin-top: 30px;
            padding: 0;
        }
        .title {
            order: 1;
            font-size: calc(20px + 10*(100vw - 320px)/1280);
            font-weight: 700;
            line-height: 1.4;
            text-align: justify;
        }
        .box {
            order: 3;
            margin-top: 20px;
            text-align: justify;
            p + p {
                margin-top: 20px;
            }
        }
        .copy {
            order: 4;
            margin-top: 10px;
            text-align: right;
        }
    }
}



.sec-column {
    width: 100%;
    &--title {
        position: relative;
        height: 320px;
        width: 100%;
        @include media(sm) {
            height: 240px;
        }
        .title {
            font-size: calc(18px + 12*(100vw - 320px)/1280);
            line-height: 1.3;
            font-weight: 400;
            letter-spacing: 5px;
            text-align: center;
            position: absolute;
            top: 30%;
            left: 50%;
            display: block;
            transform: translate(-50%, 0);
            @include media(xs) {
                font-weight: 500;
            }
        }
    }
    &--pic {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
    &.sec-column-top {
        .sec-column--inner {
            margin-top: -100px;
            background-color: white;
            padding: 60px;
            @include media(lg) {
                max-width: 90%;
            }
            @include media(md) {
                padding: 40px;
            }
            @include media(sm) {
                margin-top: -60px;
            }
            @include media(xs) {
                padding: 20px;
            }
        }
    }
    &--inner {
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
        z-index: 1;
        @include media(sm) {
            max-width: 90%;
        }
    }
    &--list {
        display: flex;
        flex-flow: row wrap;
        margin: -50px 0 0 -30px;
    }
    &--item {
        padding: 50px 0 0 30px;
        flex: 0 0 auto;
        width: 33%;
        line-height: 1.4;
        @include media(md) {
            flex: 0 0 auto;
            width: 50%;
        }
        @include media(xs) {
            flex: 0 0 auto;
            width: 100%;
        }
        a {
            color: $black;
            transition: color .3s ease-in-out;
            &:hover {
                color: $main;
            }
        }
        .toggle {
            cursor: pointer;
            &:hover {
                span {
                    color: $main;
                    &:after {
                        border-top: 4px solid $main;
                    }
                }
            }
            span {
                position: relative;
                display: block;
                line-height: 1.4;
                font-weight: 700;
                color: $black;
                transition: color .3s ease-in-out;
                padding: 20px 20px 20px 10px;
                border-top: 1px solid $pale;
                &:after {
                    position: absolute;
                    display: inline-block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border: 4px solid transparent;
                    border-top: 4px solid $black;
                    margin-left: 5px;
                    vertical-align: middle;
                    top: 50%;
                    right: 10px;
                    transform: translate(0, -50%);
                }
            }
            &.active {
                span {
                    &:after {
                        border-bottom: 4px solid $black;
                        border-top: none;
                    }
                }
            }
        }
        .child {
            padding: 0 0 0 10px;
            font-size: calc(14px + 2*(100vw - 320px)/1280);
            line-height: 1.4;
            &.hide {
                display: none;
            }
            &--item {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }
}


// clinical-index
.sec-time {
    .table-simple {
        thead {
            background-color: $key;
        }
        td {
            width: 10%;
            min-width: 65px;
        }

    }
}


// clinical-oneday
.sec-form {
    background-color: $thin;
    padding: 100px 5%;
    @include media(sm) {
        padding: 60px 5%;
    }
    &--title {
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        text-align: center;
    }
    &--text {
        margin-top: 20px;
        text-align: center;
    }
    &--inner {
        max-width: 960px;
        margin: 40px auto 0;
        @include media(sm) {
            margin: 20px auto 0;
        }
    }
}

#mailform {
    .mailform {
        width: 100%;
        border-collapse: collapse;
        line-height: 1.3;
        border-top: 1px solid $pale;
        @include media(sm) {
            display: block;
        }
    }
    tbody {
        width: 100%;
        @include media(sm) {
            display: block;
        }
    }
    tr {
        width: 100%;
        border-bottom: 1px solid $pale;
        @include media(sm) {
            display: block;
        }
    }
    th {
        position: relative;
        width: 35%;
        padding: 16px 0 16px 32px;
        font-weight: normal;
        text-align: left;
        vertical-align: middle;
        @include media(sm) {
            display: block;
            width: 100%;
            padding: 16px 0 0 0;
        }
        div {
            position: relative;
        }
        span {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            padding: 4px;
            transform: translate(0, -50%);
            color: $em;
            font-weight: normal;
            line-height: 1em;
            @include media(sm) {
                position: relative;
                display: inline-block;
                transform: translate(0, 0);
                padding: 0 0 0 4px;
            }
        }
    }
    td {
        width: 65%;
        padding: 16px 0 16px 32px;
        vertical-align: middle;
        line-height: 1.8;
        @include media(sm) {
            display: block;
            width: 100%;
            padding: 10px 0 16px 0;
        }
        &.post {
            input[type="text"] {
                width: 40%;
                margin-right: 10px;
            }
        }
        &.birth {
            select {
                @include media(sm) {
                    padding: 10px 2px;
                }
            }
        }
        input[type="text"] {
            width: 100%;
            padding: 10px;
            border: none;
            font-family: $font;
        }
        textarea {
            width: 100%;
            padding: 10px;
            min-height: 200px;
            border: none;
            font-family: $font;
        }
        select {
            padding: 10px;
            border: none;
            font-family: $font;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
    .mailcheck {
        text-align: center;
    }
    .mailsend {
        text-align: center;
        input[type="submit"] {
            display: inline-block;
            min-width: 240px;
            padding: 12px 16px;
            background: $main;
            border: 1px solid $main;
            color: white;
            font-family: $font;
            text-align: center;
            transition: .3s;
            &:hover {
                background: white;
                color: $main;
            }
        }
        input[type="reset"] {
            display: inline-block;
            min-width: 160px;
            padding: 8px 12px;
            background: $pale;
            border: 1px solid $pale;
            color: $black;
            font-family: $font;
            text-align: center;
            transition: .3s;
            margin-top: 20px;
            &:hover {
                background: white;
            }
        }
    }
}

// clinical-carrer
.sec-full {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    position: relative;
    min-height: 60vh;
    max-height: 60vh;
    @include media(sm) {
        flex-flow: column nowrap;
        min-height: inherit;
        max-height: inherit;
    }
    &:before {
        display: block;
        padding-top: 100%;
        content: '';
        @include media(sm) {
            content: none;
        }
    }
    &--pic {
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            @include media(sm) {
                position: static;
            }
        }
    }
    &--box {
        width: 50%;
        margin-left: auto;
        z-index: 10;
        padding-left: 10%;
        line-height: 1.3;
        @include media(sm) {
            width: 100%;
            margin-bottom: 40px;
            padding: 0 5%;
        }
    }
    &--title {
        font-size: calc(20px + 20*(100vw - 320px)/1280);
        font-weight: 500;
    }
    &--text {
        font-size: calc(16px + 8*(100vw - 320px)/1280);
        margin-top: 20px;
    }
}

.sec-half {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include media(sm) {
        flex-flow: column nowrap;
        min-height: inherit;
        max-height: inherit;
    }
    &.sec-half-reverse {
        flex-flow: row-reverse nowrap;
        @include media(sm) {
            flex-flow: column nowrap;
        }
    }
    &--pic {
        position: relative;
        width: 50%;
        flex: 0 1 50%;
        height: 100%;
        @include media(sm) {
            width: 100%;
            flex: 0 0 100%;
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
    &--detail {
        width: 50%;
        flex: 1 0 50%;
        padding: 0 5%;
        @include media(sm) {
            width: 100%;
            flex: 0 0 100%;
            margin-bottom: 40px;
        }
    }
    &--box {}
    &--box-sm {
        width: 60%;
        margin-right: auto;
        margin-left: auto;
    }
    &--title {
        font-size: calc(20px + 20*(100vw - 320px)/1280);
        font-weight: 500;
    }
    &--text {
        font-size: calc(16px + 8*(100vw - 320px)/1280);
        margin-top: 20px;
    }
}


.sec-carrer2 {
    img {
        object-position: 80% 50%;
    }
    .table-simple.t-noborder th,
    .table-simple.t-noborder td {
        padding: 5px 0;
    }
}













// ----------------------------------------
// メニュー
// ----------------------------------------



// ----------------------------------------
// フッター
// ----------------------------------------

.footer {
    padding: 80px 0 0;
    margin: 80px 0 0;
    background-color: $main;
    color: $thin;
    position: relative;
    @include media(sm) {
        padding: 60px 0 0;
    }
    &-inner {
        margin: 0 auto;
        @include media(sm) {
            padding: 0 5%;
        }
    }
    &-info {
        text-align: center;
        line-height: 1.3;
        &--title {
            font-size: 1.4rem;
            span {
                display: block;
                font-size: 2.2rem;
                @include media(sm) {
                    font-size: 2.4rem;
                }
            }
        }
        &--address {
            font-style: normal;
            margin-top: 10px;
            span {
                display: block;
                font-size: 1.4rem;
            }
        }
        &--tel {
            margin-top: 20px;
            font-size: calc(20px + 4*(100vw - 320px)/1280);
            line-height: 1.3;
            letter-spacing: 5px;
            font-weight: 700;
            color: $key;
            @include media(sm) {
                letter-spacing: 2px;
            }
            span {
                display: block;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                letter-spacing: 0;
                font-weight: 400;
            }
            a {
                color: $key;
                text-decoration: none;
            }
        }
    }
    &-time {
        .table-simple {
            width: inherit;
            margin: 0 auto;
            border-top: 1px solid $pale;
            thead {
                background-color: inherit;
                border-bottom: 1px solid $pale;
            }
            tbody {
                border-bottom: 1px solid $pale;
            }
            th, td {
                padding: 5px 0;
                font-size: 1.4rem;
                span {
                    font-size: 1.2rem;
                }
            }
            td {
                width: 40px;
            }
        }
    }
    &-copyright {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 20px;
        color: $aux;
    }
    &-totop {
        position: relative;
        width: 90%;
        margin: 40px auto 0;
        padding: 30px 0;
        border-top: 1px solid darken($main, 20%);
        display: block;
        @include media(sm) {
            width: 100%;
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            border-top: 1px solid darken($main, 20%);
            border-right: 1px solid darken($main, 30%);
            transform: rotate(-45deg) translate(-50%, -50%);
        }
    }
}

// ----------------------------------------
// 特殊
// ----------------------------------------

// drawer
.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.drawermenu {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 100vh;
    overflow: auto;
    background-color: $main;
    transition: opacity .3s ease-out, display .3s ease-out;
    padding: 30px 40px;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    @include media(sm) {
        width: 80vw;
    }
    @include media(xs) {
        padding: 30px 20px;
    }
    &.hide {
        display: none;
    }
    &.is-active {
        opacity: 1;
        display: block;
        z-index: 2000;
    }
    .drawermenu--burger-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: white;
            &:nth-of-type(1) {
                top: 50%;
                transform: rotate(45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar01 .75s forwards;
                animation: drawer-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                transform: rotate(-45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar03 .75s forwards;
                animation: drawer-bar03 .75s forwards;
            }
        }
    }
    &--logo {
        display: block;
        text-align: center;
        margin-top: -32px;
        &-anchor {
            display: inline-block;
            margin: 0 auto;
        }
        svg {
            width: 160px;
            height: auto;
            max-height: 40px;
            fill: white;
            @include media(sm) {
                width: 140px;
            }
        }
    }
    .swiper-slide {
        margin: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
    .swiper-pagination-drawermenu {
        text-align: center;
        .swiper-pagination-bullet {
            width: 20px;
            height: 2px;
            border-radius: 0;
        }
        .swiper-pagination-bullet-active {
            background: $key;
        }
    }
}
@-webkit-keyframes drawer-bar01 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(45deg); }
}
@keyframes drawer-bar01 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(45deg); }
}
@-webkit-keyframes drawer-bar03 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(-45deg); }
}
@keyframes drawer-bar03 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(-45deg); }
}

.card-drawermenu {
    &--anchor {
        display: block;
        position: relative;
        .caption {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translate(-50%, 0);
            .title {
                font-size: 1.2rem;
                text-align: center;
                color: white;
                line-height: 1.3;
            }
        }
    }
}

.drawer-nav {
    &--section {
        border-top: 1px solid $aux;
        padding-top: 20px;
        &-first {
            border-top: none;
            padding-top: 0;
        }
        .address {
            font-size: 1.4rem;
            line-height: 1.4;
            span {
                font-size: 1.2rem;
                display: block;
            }
        }
        .tel {
            font-size: 2.0rem;
            font-weight: 700;
            line-height: 1.3;
            color: $key;
            a {
                color: $key;
            }
            span {
                display: block;
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
    &--title {
        font-size: 1.4rem;
        margin-bottom: 10px;
        a {
            color: $pale;
            transition: color .3s ease-in-out;
            &:hover {
                color: $key;
            }
        }
    }
    &--list {}
    &--item {
        line-height: 1.4;
        &:not(:first-child){
            margin-top: 10px;
        }
        a {
            color: white;
            font-size: 1.6rem;
            transition: color .3s ease-in-out;
            @include media(sm) {
                font-size: 1.4rem;
            }
            &:hover {
                color: $key;
            }
        }
        .toggle {
            cursor: pointer;
            &:hover {
                span {
                    color: $key;
                    &:after {
                        border-top: 4px solid $key;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: 1.6rem;
                line-height: 1.3;
                color: white;
                transition: color .3s ease-in-out;
                @include media(sm) {
                    font-size: 1.4rem;
                }
                &:after {
                    display: inline-block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border: 4px solid transparent;
                    border-top: 4px solid white;
                    margin-left: 5px;
                    vertical-align: middle;
                }
            }
            &.active {
                span {
                    color: $key;
                    &:after {
                        border-bottom: 4px solid $key;
                        border-top: none;
                    }
                }
            }
        }
        .child {
            padding-left: 20px;
            margin: 0 0 10px;
            line-height: 1.3;
            @include media(sm) {
                padding-left: 10px;
            }
            &.hide {
                display: none;
            }
            &--item {
                margin-top: 10px;
            }
        }
    }
}


.page-cover {
    display: none;
    &.is-active {
        display: block;
        z-index: 1900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
}


// contentbottom
.sec-contentbottom {
    margin-top: 160px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
    @include media(sm) {
        max-width: 80%;
    }
    @include media(xs) {
        margin-top: 80px;
        max-width: 90%;
    }
    &--title {
        font-size: calc(18px + 12*(100vw - 320px)/1280);
        text-align: center;
    }
    .swiper-contentbottom {
        padding: 0;
    }
    .swiper-slide {
        margin: 0;
    }
    .swiper-pagination-contentbottom {
        text-align: center;
        .swiper-pagination-bullet {
            width: 20px;
            height: 2px;
            border-radius: 0;
        }
        .swiper-pagination-bullet-active {
            background: $key;
        }
    }
}

.card-contentbottom {
    .caption {
        margin-top: 20px;
        @include media(sm) {
            margin-top: 10px;
        }
        .title {
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
}

// slick

.slick-dots {
    bottom: 10vh;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    li {
        width: 40%;
        height: 3px;
        &.slick-active {
            background-color: #fff;
            button:before {
                opacity: 1;
                left: 0;
                -webkit-animation: slick-bar 5s linear;
                animation: slick-bar 5s linear;
                background-color: $key;
            }
        }
        button:before {
            content: '';
            background-color: $black;
            opacity: 0.4;
            width: 100%;
            height: 3px;
        }
    }
}
@-webkit-keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}
@keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}

.slider:hover {
    .slick-next {
        right: 3%;
    }
    .slick-prev {
        left: 3%;
    }
}
.slick-next {
    right: -50px;
    transition: right .3s ease-in-out;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev {
    left: -50px;
    transition: left .3s ease-in-out;
    z-index: 1;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev:before,
.slick-next:before {
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    opacity: .6;
    transition: opacity .3s ease-out;
    text-shadow: $aux 0 0 10px;
}


// swiper
.swiper-container {
    padding: 0 10%;
    @include media(sm) {
        padding: 0 5%;
    }
    &.swiper-hero {
        padding: 0;
        .card {
            min-height: 100vh;
            max-height: 100vh;
        }
    }
    .swiper-slide {
        flex: 0 0 auto;
        margin: 0 auto;
        &.card-title {
            min-width: 45%;
            max-width: 45%;
            width: 45%;
            @include media(lg) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(md) {
                min-width: 70%;
                max-width: 70%;
                width: 70%;
            }
        }
        &.card-lg {
            min-width: 80%;
            max-width: 80%;
            width: 80%;
            @include media(sm) {
                min-width: 100%;
                max-width: 100%;
                width: 100%;
            }
        }
        &.card-md {
            min-width: 50%;
            max-width: 50%;
            width: 50%;
            @include media(md) {
                min-width: 90%;
                max-width: 90%;
                width: 90%;
            }
            @include media(sm) {
                min-width: 100%;
                max-width: 100%;
                width: 100%;
            }
        }
        &.card-sm {
            min-width: 30%;
            max-width: 30%;
            width: 30%;
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(sm) {
                min-width: 60%;
                max-width: 60%;
                width: 60%;
            }
            @include media(xs) {
                min-width: 80%;
                max-width: 80%;
                width: 80%;
            }
            .card {
                min-height: 30vh;
                max-height: 30vh;
                height: 30vh;
            }
        }
    }
    a.card {
        &:hover {
            .pic {
                transform: scale(1.05);
            }
        }
    }
    .card {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        flex-flow: column nowrap;
        .pic {
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: transform .3s;
        }
        .caption-title {
            position: absolute;
            left: 5vw;
            bottom: 10%;
            .title {
                color: white;
                font-size: calc(18px + 22*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 10px;
                font-weight: 500;
            }
            .text {
                color: white;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.4;
                margin-top: 10px;
                text-shadow: $black 0 0 10px;
            }
            .button {
                margin-top: 20px;

            }
        }
        .caption-headline-item {
            padding: 20px 5px 0;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .title {
                font-weight: normal;
                color: white;
                font-size: calc(18px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 5px;
            }
        }
        .caption-number {
            padding-right: 5%;
            width: 100%;
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .number {
                font-size: 4.0rem;
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .title {
                font-size: calc(16px + 22*(100vw - 320px)/1280);
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .text {
                text-align: justify;
            }
        }
        .caption-category {
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-size: 1.4rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
            .detail {
                font-size: 3.0rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
        }
        .caption-text {
            padding: 0 5%;
        }
        .caption-desc {
            width: 100%;
            background-color: white;
            padding: 10px;
            .text {
                font-size: calc(16px - 2*(100vw - 320px)/1280);
                line-height: 1.3;
            }
        }
    }
    &:hover {
        .swiper-button-prev {
            left: 5%;
        }
        .swiper-button-next {
            right: 5%;
        }
    }
}


.swiper-button-prev,
.swiper-button-next {
    width: 40px;
    height: 40px;
    margin-top: -5%;
    transition: left .3s ease-in-out, right .3s ease-in-out;
    opacity: .6;
    filter: drop-shadow(0 0 10px $aux);
    @include media(md) {
        width: 30px;
        height: 30px;
    }
    @include media(xs) {
        width: 20px;
        height: 20px;
    }
    &:hover {
        opacity: 1;
    }
}
.swiper-button-prev {
    left: -20%;
    background: url(images/i-arrow-left.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-button-next {
    right: -20%;
    background: url(images/i-arrow-right.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-container-horizontal {
    & > .swiper-scrollbar {
        bottom: 0;
        border-radius: 0;
        width: 90%;
        left: 5%;
        position: relative;
        padding: 30px 0;
        height: 2px;
        background: transparent 50%/2px url(images/bg-swiper-scrollbar.png) repeat-x !important;
        @include media(sm) {
            padding: 20px 0;
        }
        &:hover {
            .swiper-scrollbar-drag {
                &:after {
                    height: 6px;
                    top: -3px;
                    border-radius: 10px;
                }
            }
        }
        .swiper-scrollbar-drag {
            background-color: $key;
            border-radius: 0;
            &:after {
                background: $key;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
                top: -1px;
                transition: height .2s ease-out, top .2s ease-in-out, border-radius .2s ease-in-out;
            }
        }
    }
}
