@charset "UTF-8";

// ========================================
// モジュール設定
// ========================================

// デザインスタイル
// ----------------------------------------



.sec-clip {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    padding: 120px 10%;
    &-inner {
        background-color: white;
        padding: 80px 5%;
        border: 10px solid rgba(255, 255, 255, .3);
        background-clip: padding-box;
    }
    hgroup {
        background-clip: text;
        -webkit-background-clip: text;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
    }
    .number {
        color: rgba(0, 0, 0, .4);
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        font-weight: black;
        line-height: 1.3;
    }
    .title {
        span {
            color: rgba(0, 0, 0, .4);
            font-size: calc(18px + 22*(100vw - 320px)/1280);
            font-weight: black;
            line-height: 1.3;
        }
    }
}

.sec-main {
    max-width: 960px;
    padding: 0 5%;
    margin-right: auto;
    margin-left: auto;
}

.grid {
    display: flex;
    flex-flow: row wrap;
    margin: -20px 0 0 -20px;
    &.xl-4 {
        .col {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    &.xl-3 {
        .col {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }
    &.xl-2 {
        .col {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .col {
        display: block;
        align-self: center;
        text-align: center;
        padding: 20px 0 0 20px;
        min-width: 0;
    }
}



a:hover {
    .button {
        border: 1px solid $main;
        background-color: $main;
        color: white;
    }
}
.button {
    display: inline-block;
    padding: 16px 40px;
    min-width: 240px;
    position: relative;
    border-radius: 100px;
    border: 1px solid white;
    background-color: white;
    color: $main;
    transition: color .3s, background .3s, border .3s;
    text-align: center;
    line-height: 1.3;
    @include media(xs) {
        min-width: 200px;
    }
}

.btn {
    a {
        display: inline-block;
        padding: 16px 40px;
        min-width: 240px;
        position: relative;
        border-radius: 100px;
        border: 1px solid $main;
        background-color: $main;
        color: white;
        transition: color .3s, background .3s;
        text-align: center;
        line-height: 1.3;
        &:hover {
            border: 1px solid $main;
            background-color: white;
            color: $main;
        }
    }
}



// title

.title-lg {
    font-size: calc(24px + 12*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 400;
    @include media(sm) {
        font-weight: 500;
    }
}
.title-md {
    font-size: calc(20px + 10*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 400;
    @include media(sm) {
        font-weight: 500;
    }
}
.title-sm {
    font-size: calc(16px + 2*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 400;
    @include media(sm) {
        font-weight: 500;
    }
}


.title-main {
    font-size: calc(24px + 16*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 400;
    @include media(sm) {
        font-weight: 500;
    }
}
.title-main-b {
    font-size: calc(24px + 16*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 700;
}

.title-sub {
    font-size: calc(18px + 12*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 400;
    @include media(sm) {
        font-weight: 500;
    }
}
.title-sub-b {
    font-size: calc(18px + 12*(100vw - 320px)/1280);
    line-height: 1.3;
    font-weight: 700;
}

.title-blur {
    span {
        background-clip: text;
        -webkit-background-clip: text;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(images/mv-concept.jpg);
        color: rgba(0, 0, 0, .4);
        font-size: calc(18px + 20*(100vw - 320px)/1280);
        font-weight: 700;
        line-height: 1.3;
        @media all and (-ms-high-contrast: none){
            background-image: none;
            color: $black;
        }
    }
}

.title-bg {
    position: relative;
    display: table;
    width: 100%;
    min-height: 400px;
    @include media(sm) {
        min-height: 300px;
    }
    .title {
        display: table-cell;
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        text-align: center;
        color: white;
        font-weight: 400;
        letter-spacing: 10px;
        vertical-align: middle;
        @include media(sm) {
            letter-spacing: 5px;
        }
    }
    .pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
}

// block

.block-memo {
    padding: 0 5%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    color: $aux;
}

.block-article {
    max-width: 960px;
    padding: 0 5%;
    margin-right: auto;
    margin-left: auto;
    &--wrap {
        overflow-x: hidden;
    }
    p {
        text-align: justify;
    }
    h1 + p, h2 + p {
        margin-top: 30px;
    }
    h3 + p, h4 + p {
        margin-top: 20px;
    }
    figure + p, p + figure {
        margin-top: 80px;
        @include media(sm) {
            margin-top: 40px;
        }
    }
    p + p {
        margin-top: 30px;
    }
}

.block-lead {
    text-align: center;
    padding: 0 5%;
    margin-right: auto;
    margin-left: auto;
    p + p {
        margin-top: 20px;
    }
}

.block-map {
    width: 100%;
    height: 600px;
    @include media(sm) {
        height: 50vh;
    }
}

.block-3column {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 960px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    &--item {
        max-width: 32%;
        margin-bottom: 40px;
    }
    .caption {
        margin-top: 20px;
        .title {
            font-size: 1.6rem;
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
}

.block-4column {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    @include media(sm) {
        width: 90%;
    }
    &--list {
        display: flex;
        flex-flow: row wrap;
        margin: -20px 0 0 -10px;
        @include media(sm) {
            margin: -40px 0 0 -10px;
        }
    }
    &--item {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 20px 0 0 10px;
        @include media(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 40px 0 0 10px
        }
    }
    .caption {
        margin-top: 20px;
        .title {
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            line-height: 1.3;
            color: $black;
            font-weight: normal;
        }
    }
}
.block-bnr-em {
    &--anchor {
        position: relative;
        width: 100%;
        background-size: cover;
        display: block;
        .caption {
            padding-left: 10%;
            padding-right: 10%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            .title {
                font-size: 1.4rem;
                color: white;
                padding-bottom: 40px;
            }
            .copy {
                font-size: 4.0rem;
                font-weight: normal;
                color: white;
                line-height: 1.3;
            }
        }
    }
}

.grid-link {
    width: 80%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    @include media(sm) {
        width: 90%;
    }
    &--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    &--item {
        width: calc(50% - 5px);
        position: relative;
        margin-bottom: 10px;
    }
    &--item-full {
        width: 100%;
    }
    .main-title {
        font-size: 1.6rem;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    .caption {
        position: absolute;
        top: 5%;
        width: 100%;
        .title {
            font-size: calc(16px + 8*(100vw - 320px)/1280);
            line-height: 1.3;
            text-align: center;
            color: white;
            text-shadow: $aux 0 0 10px;
            font-weight: 500;
        }
    }
}


// table

.table-wrap {
    @include media(sm) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    th, td {
        white-space: nowrap;
    }
}
.table-simple {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $pale;
    line-height: 1.3;
    thead {
        background-color: $thin;
    }
    th, td {
        border: 1px solid $pale;
        padding: 20px 10px;
        font-weight: 400;
    }
    &.t-center {
        text-align: center;
    }
    &.t-noborder {
        border: none;
        th, td {
            border: none;
            padding: 10px;
        }
    }
    &.th-left {
        th {
            text-align: left;
        }
    }
    &.td-right {
        td {
            text-align: right;
        }
    }
    &.td-center {
        td {
            text-align: center;
        }
    }
}
.table-row {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $pale;
    line-height: 1.3;
    @include media(sm) {
        border-collapse: separate;
        border-spacing: 0;
    }
    thead {
        background-color: $thin;
    }
    th, td {
        border: 1px solid $pale;
        padding: 20px 10px;
        font-weight: 400;
        @include media(sm) {
            display: block;
            border: 0;
        }
    }
    th {
        @include media(sm) {
            padding: 15px 10px 10px;
        }
    }
    td {
        @include media(sm) {
            padding: 0 10px 15px;
        }
    }
    tr:not(:first-child) th {
        border-top: 1px solid $pale;
    }
}



// list

.list-circle {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "●";
      top: 0;
      left: 0;
    }
  }
}
.list-asta {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "＊";
      top: 0;
      left: 0;
    }
  }
}
.list-note {
  & > li {
    position: relative;
    padding-left: 18px;
    &:before {
      position: absolute;
      content: "※";
      top: 0;
      left: 0;
    }
  }
}
.list-disc {
  & > li {
    position: relative;
    padding-left: 14px;
    &:before {
      position: absolute;
      content: "・";
      top: 0;
      left: 0;
    }
  }
}

.list-number {
    list-style-type: none;
    list-style-position: inside;
}
    .list-number > li {
        padding-left: 1.5em;
        text-indent: -1.5em;
        counter-increment: cnt;
        text-align: justify;
    }
    .list-number > li:before {
        display: marker;
        content: "0" counter(cnt) " ";
        margin-right: 3px;
        color: $aux;
    }
    .list-number > li:nth-child(n+10):before {
        content: "" counter(cnt) " ";
    }




.list-flow-down {
    .flow {
        position: relative;
        display: block;
        margin: 0;
        padding: 20px 10px;
        min-width: 120px;
        max-width: 100%;
        color: white;
        background: $main;
        text-align: center;
        line-height: 1.3;
        &:not(:last-child) {
            &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -15px;
                border: 15px solid transparent;
                border-top: 15px solid $main;
            }
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}


// etc

.zoom {
    overflow: hidden;
    a:hover & {
        img {
            transform: scale(1.05);
        }
    }
    img {
        transition: transform .3s;
    }
}

.fig-caption {
    margin: -10px 0;
    img {
        padding: 10px 0;
    }
    figcaption {
        margin: 10px 0;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}
.fig-caption-lg {
    margin: 1em calc(50% - 50vw);
    img {
        width: 100%;
        height: auto;
    }
    figcaption {
        margin: 10px auto;
        max-width: 960px;
        padding: 0 5%;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}
.release-pic {
    margin: 1em calc(50% - 50vw);
    .pic {
        width: 100%;
        height: 600px;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .notes {
        margin: 10px auto;
        max-width: 960px;
        padding: 0 5%;
        font-size: 86%;
        line-height: 1.4;
        color: $aux;
    }
}









/* sef
--------------------------------------------- */
.sef {
    opacity: 0;
    transition: all .5s cubic-bezier(.39,.575,.565,1);
}

.sef.sef-ori {
    opacity: 1;
}

.sef.show {
    opacity: 1;
    transform: none;
}

.sef-left {
transform: translate(-100px, 0);
}

.sef-right {
transform: translate(100px, 0);
}

.sef-up {
transform: translate(0, 100px);
}

.sef-down {
transform: translate(0, -100px);
}

.sef-scaleUp {
transform: scale(.5);
}

.sef-scaleDown {
transform: scale(1.5);
}


/* カラー
--------------------------------------------- */
.c-white { color: white; }
.c-black { color: $black; }
.c-aux { color: $aux; }
.c-pale { color: $pale; }
.c-thin { color: $thin; }
.c-main { color: $main; }
.c-sub { color: $sub; }
.c-key { color: $key; }
.c-em { color: $em; }
.c-link { color: $link; }


.bg-white { background-color: white; }
.bg-black { background-color: $black; }
.bg-aux { background-color: $aux; }
.bg-pale { background-color: $pale; }
.bg-thin { background-color: $thin; }
.bg-deep { background-color: $main; }
.bg-deep-thin { background-color: lighten($main, 30%); }
.bg-cool { background-color: $sub; }
.bg-cool-thin { background-color: lighten($sub, 30%); }
.bg-light { background-color: $em; }
.bg-light-thin { background-color: lighten($em, 30%); }
.bg-key { background-color: $key; }
.bg-key-thin { background-color: lighten($key, 30%); }



/* スペース
--------------------------------------------- */
.mt-skin  { margin-top:  10px !important; }
.mt-min   { margin-top:  20px !important; }
.mt-xs    { margin-top:  30px !important; }
.mt-sm    { margin-top:  40px !important;
    @include media(sm) { margin-top: 30px !important; }
}
.mt-md   { margin-top:  60px !important;
    @include media(sm) { margin-top: 40px !important; }
}
.mt-lg   { margin-top:  80px !important;
    @include media(sm) { margin-top: 60px !important; }
}
.mt-xl   { margin-top: 120px !important;
    @include media(sm) { margin-top: 80px !important; }
}

.mr-skin  { margin-right:  10px !important; }
.mr-min   { margin-right:  20px !important; }
.mr-xs    { margin-right:  30px !important; }

.ml-skin  { margin-left:  10px !important; }
.ml-min   { margin-left:  20px !important; }
.ml-xs    { margin-left:  30px !important; }



// フォント設定
// ----------------------------------------
.ff { font-family: $font !important;; }
.ff-min { font-family: $font-min !important;; }

.fw-b { font-weight: bold !important; }
.fw-n { font-weight: normal !important; }

.fs-min { font-size:  72% !important; }
.fs-xs  { font-size:  86% !important; }
.fs-sm   { font-size:  93% !important; }
.fs-md   { font-size: 115% !important; }
.fs-lg  { font-size: 143% !important; }
.fs-xl { font-size: 172% !important; }


.fs10 { font-size: 1.0rem !important; }
.fs11 { font-size: 1.1rem !important; }
.fs12 { font-size: 1.2rem !important; }
.fs13 { font-size: 1.3rem !important; }
.fs14 { font-size: 1.4rem !important; }
.fs15 { font-size: 1.5rem !important; }
.fs16 { font-size: 1.6rem !important; }
.fs18 { font-size: 1.8rem !important; }
.fs20 { font-size: 2.0rem !important; }
.fs24 { font-size: 2.4rem !important; }
.fs28 { font-size: 2.8rem !important; }
.fs30 { font-size: 3.0rem !important; }
.fs36 { font-size: 3.6rem !important; }
.fs40 { font-size: 4.0rem !important; }
.fs48 { font-size: 4.8rem !important; }

.lh10 { line-height: 1.0 !important; }
.lh12 { line-height: 1.2 !important; }
.lh13 { line-height: 1.3 !important; }
.lh14 { line-height: 1.4 !important; }
.lh15 { line-height: 1.5 !important; }
.lh16 { line-height: 1.6 !important; }
.lh18 { line-height: 1.8 !important; }
.lh20 { line-height: 2.0 !important; }
.lh30 { line-height: 3.0 !important; }


// ツール設定
// ----------------------------------------
.ta-l { text-align: left !important;}
.ta-c { text-align: center !important;}
.ta-r { text-align: right !important;}
.ta-j { text-align: justify !important;}

.ta-cl {
    text-align: center !important;
    @include media(md) {
        text-align: left !important;
    }
}

@include media(xs) {
    .xs-w90c {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
}

.xl-on {
    display: none;
    @include media(xl) {
        display: block;
    }
}
.xl-no {
    @include media(xl) {
        display: none;
    }
}
.lg-on {
    display: none;
    @include media(lg) {
        display: block;
    }
}
.lg-no {
    @include media(lg) {
        display: none;
    }
}
.md-on {
    display: none;
    @include media(md) {
        display: block;
    }
}
.md-no {
    @include media(md) {
        display: none;
    }
}
.sm-on {
    display: none;
    @include media(sm) {
        display: block;
    }
}
.sm-no {
    @include media(sm) {
        display: none;
    }
}
.xs-on {
    display: none;
    @include media(xs) {
        display: block;
    }
}
.xs-no {
    @include media(xs) {
        display: none;
    }
}

a.line {
    text-decoration: underline;
}


.d-block { display: block; }
.d-iblock { display: inline-block; }
.d-table { display: table; }
.d-tablec { display: table-cell; }
.d-inline { display: inline; }







// clearfix
// ----------------------------------------
.clearfix {
    display: block;
    min-height: 1%;
}
.clearfix:after {
    clear: both;
    content:"";
    display: block;
    height: 0;
    visibility: hidden;
}
* html .clearfix {
    height: 1%;
    /*\*//*/
    height: auto;
    overflow: hidden;
    /**/
}
.clear {
    clear: both;
}